import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { withNamespaces } from 'react-i18next';
import $ from "jquery";
import SimpleBar from "simplebar-react";

import Select1 from "react-select";
import customStyles from '../../../../assets/select/customStyles'

import SelectType from '../../../CommonComponents/SelectType'

import InputColor from 'react-input-color';

import NumericInput from 'react-numeric-input';

import SettingsText from './SettingsText.js';
import SettingsBufferHysteresis from './SettingsBufferHysteresis.js';
import SettingsLightIntensity from './SettingsLightIntensity.js';
import SettingsCountingArea from './SettingsCountingArea.js';
import SettingsNoteBox from './SettingsNoteBox.js';
import SettingsTimeMonitoring from './SettingsTimeMonitoring.js';
import SettingsLastActivities from './SettingsLastActivities.js';
import SettingsLocation from './SettingsLocation.js';
import SettingsCamera from './SettingsCamera.js';
import SettingsTags from './SettingsTags.js';
import SettingsBarrierTable from './SettingsBarrierTable.js';


//modus pictures
import autoAut from '../../../../assets/images/mgsuite/Automatic_24x24.png';
import autoOcc from '../../../../assets/images/mgsuite/ManualOccupied_24x24.png';
import autoFree from '../../../../assets/images/mgsuite/ManualFree_24x24.png';
import autoBlock from '../../../../assets/images/mgsuite/ManualBlocked_24x24.png';
import xIcon from '../../../../assets/images/mgsuite/closeicon.jpg';

const Settings = (props) => {
  {

    let jwt = localStorage.getItem('JWT')
    let link = localStorage.getItem('link')

    let data = props.data[0]
    let settingsType = props.type

    axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwt;

    // Options for select dropdown

    const optionsTextFonts = [
      { value: 'arial', label: <div style={{ fontFamily: 'arial' }} className="flex-iac settings-item">Arial</div> },
      { value: 'arialNarrow', label: <div style={{ fontFamily: 'arial narrow' }} className="flex-iac settings-item">Arial Narrow</div> },
      { value: 'courierNew', label: <div style={{ fontFamily: 'courier new' }} className="flex-iac settings-item">Courier New</div> },
      { value: 'tahoma', label: <div style={{ fontFamily: 'tahoma' }} className="flex-iac settings-item">Tahoma</div> },
      { value: 'timesNewRoman', label: <div style={{ fontFamily: 'times new roman' }} className="flex-iac settings-item">Times New Roman</div> },
      { value: 'verdana', label: <div style={{ fontFamily: 'verdana' }} className="flex-iac settings-item">Verdana</div> },
    ];

    const optionsTimeControl = [
      { value: 'automatic', label: <div className="flex-iac settings-item">{props.t('lot.modes.Automatic')}</div> },
      { value: 'free', label: <div className="flex-iac settings-item">{props.t('lot.modes.ManualFree')}</div> },
      { value: 'occupied', label: <div className="flex-iac settings-item">{props.t('lot.modes.ManualOccupied')}</div> },
    ];

    const optionsTimeControlText = [
      { value: 'automatic', label: <div className="flex-iac settings-item">{props.t('lot.modes.Automatic')}</div> },
      { value: 'free', label: <div className="flex-iac settings-item">{props.t('lot.modes.ManualFree')}</div> },
      { value: 'occupied', label: <div className="flex-iac settings-item">{props.t('lot.modes.ManualOccupied')}</div> },
      { value: 'text', label: <div className="flex-iac settings-item">{props.t('textDisplay.text')}</div> },
    ];

    const optionsTimeControlNum = [
      { value: 'automatic', label: <div className="flex-iac settings-item">{props.t('zoneDisplay.modes.Automatic')}</div> },
      { value: 'free', label: <div className="flex-iac settings-item">{props.t('numDisplay.modes.Dark')}</div> },
      { value: 'occupied', label: <div className="flex-iac settings-item">{props.t('numDisplay.modes.XXX')}</div> },
    ];


    const [selectedOptionTC, setSelectedOptionTC] = useState();


    const [color, setColor] = useState({});
    const [color2, setColor2] = useState({});
    const [makeUpdate, setMakeUpdate] = useState();


    function updateTimeControl(data) {

      function generateTime() {

        let days = ["mo", "di", "mi", "do", "fr", "sa", "so"];

        let hours = ["00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30",
          "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30",
          "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30", "23:59"]

        let dayshours = []

        for (let i = 0; i < days.length; i++) {

          let hours2 = []

          for (let j = 0; j < hours.length; j++) {

            let hour

            if (i == 0) {
              let hourClass = "hour hour-firstrow " + hours[j]
              hour = <div className={hourClass} ></div>
            }

            else if (i == 6) {
              let hourClass = "hour hour-lastrow " + hours[j]
              hour = <div className={hourClass} ></div>
            }

            else {
              let hourClass = "hour " + hours[j]
              hour = <div className={hourClass} ></div>

            }

            hours2.push(hour);

          }

          let daysClass = "day " + days[i]
          let day = <div className={daysClass}> {hours2} </div>
          dayshours.push(day)
        }

        let days_wrapper =
          <div id="days-wrapper">
            {dayshours}
          </div>

        return (
          <div id="time-map">
            {days_wrapper}
          </div>
        )


      }


      function selectAlignButton(e) {
        $('.ab2').removeClass('selected-align-button')
        if ($(e.target).hasClass('ab2')) {
          $(e.target).addClass('selected-align-button')
        }
        else {
          $(e.target).parent().addClass('selected-align-button')
        }

      }

      return (

        <div class="container-time">
          <div className="time-control-text">
            <div className="label-container">
              <img src={xIcon} className="x-icon" onClick={() => $('.time-control-text').hide()} />
              <label class="input-container">
                <input class="input-element" type="radio" name="radio-display" style={{ marginRight: '5px' }} /> <div>{props.t('textDisplay.belegungszahl')}</div>
              </label>
            </div>
            <div className="label-container">
              <label class="input-container">
                <input class="input-element" type="radio" name="radio-display" style={{ marginRight: '5px' }} /> <div style={{ marginRight: '5px' }}>{props.t('textDisplay.text')}*</div>  <input type="text" />
              </label>
            </div>
            <div className="flex-sb" style={{ marginTop: '5px' }}>

              <Select1 className="select select-text-mini" styles={customStyles} isSearchable={false}
                options={optionsTextFonts} theme={theme => ({ ...theme, borderRadius: 3, colors: { ...theme.colors, primary: '#20a0ff' } })} />

              <NumericInput className="input-numeric input70" mobile={"auto"} min={1} max={50} defaultValue="12" />
              <InputColor initialValue="#5e72e4" onChange={setColor2} style={{ width: '30px' }} />
              <button id="align-left2" className="align-button ab2" title="Left" onClick={selectAlignButton}><i class="fa fa-align-left"></i></button>
              <button id="align-center2" className="align-button ab2" title="Center" onClick={selectAlignButton}><i class="fa fa-align-center"></i></button>
              <button id="align-right2" className="align-button ab2" title="Right" onClick={selectAlignButton}><i class="fa fa-align-right"></i></button>
            </div>
            <button class="button" style={{ marginTop: '10px' }} onClick={() => $('.time-control-text').hide()}>{props.t('allgemein.speichern')}</button>
          </div>

          {generateTime()}

        </div>
      )

    }


    function settingsTypes() {
      return (
        <div className="settings-groups">
          <div className="settings-name">{props.t('allgemein.typ')}</div>
          <SelectType data={data} type={settingsType}></SelectType>
        </div>
      )
    }

    function settingsModus() {

      function updateMode(props) {

        axios.put(link + '/api/' + settingsType + '/Mode?mode=' + props.Mode, [data.id], {

        }).then((response) => {
          data.mode = props.Mode

          setMakeUpdate(current => !current)
        });



      }


      let modeManualOcc = false
      let modeManualFree = false
      let modeAuto = false
      // let modeBlock=false


      if (data.mode == "1") {
        modeManualFree = true
      }

      else if (data.mode == "2") {
        modeManualOcc = true
      }

      else {
        modeAuto = true
      }

      //   function manualBlocked() {
      //     if (settingsType=="Area" || settingsType=="TextDisplay") {

      //       if (data.mode=="3") {
      //         modeBlock=true
      //       }

      //     return (
      //       <div className="label-container">
      //       <label class="input-container">
      //       <input type="radio" checked={modeBlock} id="mode-manual-blocked" name="radio-car" class="input-element" onClick={() => updateMode({'Mode':'3'})}/>
      //       <img src={autoBlock}  className="settings-img"/> {props.t('lot.modes.ManualBlocked')}
      //     </label>
      //     </div>
      //     )
      //   }
      // }

      return (
        <div className="settings-groups">
          <div className="settings-name">{props.t('allgemein.modus')}</div>
          <div className="label-container" >
            <label class="input-container" >
              <input type="radio" checked={modeAuto} id="mode-automatic" name="radio-car" class="input-element" onClick={() => updateMode({ 'Mode': '0' })} />
              <img src={autoAut} className="settings-img" /> {props.t('lot.modes.Automatic')}
            </label >
          </div>
          <div className="label-container">
            <label class="input-container">
              <input type="radio" checked={modeManualFree} id="mode-manual-free" name="radio-car" class="input-element" onClick={() => updateMode({ 'Mode': '1' })} />
              <img src={autoFree} className="settings-img" /> {props.t('lot.modes.ManualFree')}
            </label>
          </div>
          <div className="label-container">
            <label class="input-container">
              <input type="radio" checked={modeManualOcc} id="mode-manual-occupied" name="radio-car" class="input-element" onClick={() => updateMode({ 'Mode': '2' })} />
              <img src={autoOcc} className="settings-img" /> {props.t('lot.modes.ManualOccupied')}
            </label>
          </div>
          {/* {manualBlocked()} */}
        </div>
      )
    }



    function settingsModusBarrier() {

      function updateMode(props) {

        axios.put(link + '/api/' + settingsType + '/Mode?mode=' + props.Mode, [data.id], {

        }).then((response) => {
          data.mode = props.Mode

          setMakeUpdate(current => !current)
        });

      }

      let modeManualOpen = false
      let modeManualClosed = false
      let modeAuto = false
      let modeExtern = false

      if (data.mode == "1") {
        modeManualOpen = true
      }

      else if (data.mode == "2") {
        modeManualClosed = true
      }

      else {
        modeAuto = true
      }

      function manualExtern() {
        if (settingsType == "Barrier") {

          if (data.mode == "3") {
            modeExtern = true
          }

          return (
            <div className="label-container">
              <label class="input-container">
                <input type="radio" checked={modeExtern} id="mode-manual-blocked" name="radio-car" class="input-element" onClick={() => updateMode({ 'Mode': '3' })} />
                <img src={autoBlock} className="settings-img" /> {props.t('barrier.modes.Extern')}
              </label>
            </div>
          )
        }
      }

      return (
        <div className="settings-groups">
          <div className="settings-name">{props.t('allgemein.modus')}</div>
          <div className="label-container" >
            <label class="input-container" >
              <input type="radio" checked={modeAuto} id="mode-automatic" name="radio-car" class="input-element" onClick={() => updateMode({ 'Mode': '0' })} />
              <img src={autoAut} className="settings-img" /> {props.t('barrier.modes.Automatic')}
            </label >
          </div>
          <div className="label-container">
            <label class="input-container">
              <input type="radio" checked={modeManualOpen} id="mode-manual-open" name="radio-car" class="input-element" onClick={() => updateMode({ 'Mode': '1' })} />
              <img src={autoFree} className="settings-img" /> {props.t('barrier.modes.ManualOpen')}
            </label>
          </div>
          <div className="label-container">
            <label class="input-container">
              <input type="radio" checked={modeManualClosed} id="mode-manual-closed" name="radio-car" class="input-element" onClick={() => updateMode({ 'Mode': '2' })} />
              <img src={autoOcc} className="settings-img" /> {props.t('barrier.modes.ManualClosed')}
            </label>
          </div>
          {manualExtern()}
        </div>
      )
    }



    function settingsModusZoneDisplays() {

      function updateZoneDisplays(props) {

        axios.put(link + '/api/' + settingsType + '/ArrowElementMode?elementId=' + data.id + '&arrowElement=' + props.arrowElement + '&mode=' + props.Mode, {

        }).then((response) => {

          if (props.arrowElement == "0") {
            if (props.Mode == "0") {
              data.leftArrowMode = "0"
            }

            else if (props.Mode == "1") {
              data.leftArrowMode = "1"
            }

            else {
              data.leftArrowMode = "2"
            }
          }

          else if (props.arrowElement == "1") {
            if (props.Mode == "0") {
              data.aheadArrowMode = "0"
            }

            else if (props.Mode == "1") {
              data.aheadArrowMode = "1"
            }

            else {
              data.aheadArrowMode = "2"
            }
          }


          else if (props.arrowElement == "2") {
            if (props.Mode == "0") {
              data.rightArrowMode = "0"
            }

            else if (props.Mode == "1") {
              data.rightArrowMode = "1"
            }

            else {
              data.rightArrowMode = "2"
            }
          }

          else if (props.arrowElement == "3") {
            if (props.Mode == "0") {
              data.xSignMode = "0"
            }

            else if (props.Mode == "1") {
              data.xSignMode = "1"
            }

            else {
              data.xSignMode = "2"
            }
          }

          setMakeUpdate(current => !current)
        });



      }

      let leftManualOff = false
      let leftManualOn = false
      let leftAuto = false
      let color1 = "black"

      if (data.leftArrow == true) {
        color1 = "green"
      }

      if (data.leftArrowMode == "0") {
        leftAuto = true
      }

      else if (data.leftArrowMode == "1") {
        leftManualOn = true
      }

      else {
        leftManualOff = true
      }

      let aheadManualOff = false
      let aheadManualOn = false
      let aheadAuto = false
      let color2 = "black"

      if (data.aheadArrow == true) {
        color2 = "green"
      }

      if (data.aheadArrowMode == "0") {
        aheadAuto = true
      }

      else if (data.aheadArrowMode == "1") {
        aheadManualOn = true
      }

      else {
        aheadManualOff = true
      }

      let rightManualOff = false
      let rightManualOn = false
      let rightAuto = false
      let color3 = "black"

      if (data.rightArrow == true) {
        color3 = "green"
      }

      if (data.rightArrowMode == "0") {
        rightAuto = true
      }

      else if (data.rightArrowMode == "1") {
        rightManualOn = true
      }

      else {
        rightManualOff = true
      }

      let symManualOff = false
      let symManualOn = false
      let symAuto = false
      let color4 = "black"

      if (data.xSign == true) {
        color4 = "red"
      }

      if (data.xSignMode == "0") {
        symAuto = true
      }

      else if (data.xSignMode == "1") {
        symManualOn = true
      }

      else {
        symManualOff = true
      }


      let allManualOff = false
      let allManualOn = false
      let allAuto = false

      if (data.xSignMode == "0" && data.rightArrowMode == "0" && data.leftArrowMode == "0" && data.aheadArrowMode == "0") {
        allAuto = true
      }

      else if (data.xSignMode == "1" && data.rightArrowMode == "1" && data.leftArrowMode == "1" && data.aheadArrowMode == "1") {
        allManualOn = true
      }

      else if (data.xSignMode == "2" && data.rightArrowMode == "2" && data.leftArrowMode == "2" && data.aheadArrowMode == "2") {
        allManualOff = true
      }


      return (
        <div className="settings-content">
          <div className="settings-name" style={{ width: '90%', marginBottom: '10px', marginTop: '10px' }}>{props.t('allgemein.modus')}</div>
          <div className="settings-groups">
            <div className="flex-sa">
              <div className="flex-sa">
                <svg id="zone-arrow-1" fill={color1} viewBox="0 0 24 24"><path d="M0 12l9-8v6h15v4h-15v6z" /></svg>
                {props.t('zoneDisplay.leftArrow')}</div>
              <div className="flex-sa"> <svg id="zone-arrow-2" fill={color2} viewBox="0 0 24 24"><path d="M0 12l9-8v6h15v4h-15v6z" /></svg> {props.t('zoneDisplay.aheadArrow')}</div>
              <div className="flex-sa">  <svg id="zone-arrow-3" fill={color3} viewBox="0 0 24 24"><path d="M0 12l9-8v6h15v4h-15v6z" /></svg> {props.t('zoneDisplay.rightArrow')}</div>
            </div>

            <div className="flex-sa" style={{ marginTop: '10px' }}>
              <div>
                <div className="label-container">
                  <label class="input-container">
                    <input type="radio" name="radio-zone3" id="arrow-left-automatic" checked={leftAuto} class="input-element" onClick={() => updateZoneDisplays({ 'arrowElement': '0', 'Mode': '0' })} />
                    {props.t('zoneDisplay.modes.Automatic')}
                  </label>
                </div>
                <div className="label-container">
                  <label class="input-container">
                    <input type="radio" name="radio-zone3" id="arrow-left-manual-on" checked={leftManualOn} class="input-element" onClick={() => updateZoneDisplays({ 'arrowElement': '0', 'Mode': '1' })} />
                    {props.t('zoneDisplay.modes.ManualOn')}
                  </label>
                </div>
                <div className="label-container">
                  <label class="input-container">
                    <input type="radio" name="radio-zone3" id="arrow-left-manual-off" checked={leftManualOff} class="input-element" onClick={() => updateZoneDisplays({ 'arrowElement': '0', 'Mode': '2' })} />
                    {props.t('zoneDisplay.modes.ManualOff')}
                  </label>
                </div>
              </div>

              <div>
                <div className="label-container">
                  <label class="input-container">
                    <input type="radio" name="radio-zone4" id="arrow-ahead-automatic" checked={aheadAuto} class="input-element" onClick={() => updateZoneDisplays({ 'arrowElement': '1', 'Mode': '0' })} />
                    {props.t('zoneDisplay.modes.Automatic')}
                  </label>
                </div>
                <div className="label-container">
                  <label class="input-container">
                    <input type="radio" name="radio-zone4" id="arrow-ahead-manual-on" checked={aheadManualOn} class="input-element" onClick={() => updateZoneDisplays({ 'arrowElement': '1', 'Mode': '1' })} />
                    {props.t('zoneDisplay.modes.ManualOn')}
                  </label>
                </div>
                <div className="label-container">
                  <label class="input-container">
                    <input type="radio" name="radio-zone4" id="arrow-ahead-manual-off" checked={aheadManualOff} class="input-element" onClick={() => updateZoneDisplays({ 'arrowElement': '1', 'Mode': '2' })} />
                    {props.t('zoneDisplay.modes.ManualOff')}
                  </label>
                </div>
              </div>

              <div>
                <div className="label-container">
                  <label class="input-container">
                    <input type="radio" name="radio-zone5" id="arrow-right-automatic" checked={rightAuto} class="input-element" onClick={() => updateZoneDisplays({ 'arrowElement': '2', 'Mode': '0' })} />
                    {props.t('zoneDisplay.modes.Automatic')}
                  </label>
                </div>
                <div className="label-container">
                  <label class="input-container">
                    <input type="radio" name="radio-zone5" id="arrow-right-manual-on" checked={rightManualOn} class="input-element" onClick={() => updateZoneDisplays({ 'arrowElement': '2', 'Mode': '1' })} />
                    {props.t('zoneDisplay.modes.ManualOn')}
                  </label>
                </div>
                <div className="label-container">
                  <label class="input-container">
                    <input type="radio" name="radio-zone5" id="arrow-right-manual-off" checked={rightManualOff} class="input-element" onClick={() => updateZoneDisplays({ 'arrowElement': '2', 'Mode': '2' })} />
                    {props.t('zoneDisplay.modes.ManualOff')}
                  </label>
                </div>
              </div>

            </div>
          </div>


          <div className="settings-groups">
            <div className="flex-sa" >
              <div>{/* {props.t('zoneDisplay.alleElemente')} */}</div>
              <div className="flex-sa">
                <div className="svg-arrow">
                  <svg id="zone-arrow-4" fill={color4} viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" /></svg>
                </div>
                {props.t('zoneDisplay.symbol')}
              </div>
            </div>

            <div className="flex-sa" style={{ marginTop: '10px' }}>

              <div>
                <div className="label-container">
                  {/*
           <label class="input-container">
              <input type="radio" name="radio-zone1" id="all-automatic" checked={allAuto} class="input-element"/>
               {props.t('zoneDisplay.modes.Automatic')}
            </label>
            </div>
            <div className="label-container">
            <label class="input-container">
              <input type="radio" name="radio-zone1" id="all-manual-on" checked={allManualOn} class="input-element"/>
              {props.t('zoneDisplay.modes.ManualOn')}
            </label>
            </div>
            <div className="label-container">
            <label class="input-container">
              <input type="radio" name="radio-zone1" id="all-manual-off" checked={allManualOff} class="input-element"/>
              {props.t('zoneDisplay.modes.ManualOff')}
            </label>  
        */}
                </div>
              </div>


              <div>
                <div className="label-container">
                  <label class="input-container">
                    <input type="radio" name="radio-zone2" id="symbol-automatic" checked={symAuto} class="input-element" onClick={() => updateZoneDisplays({ 'arrowElement': '3', 'Mode': '0' })} />
                    {props.t('zoneDisplay.modes.Automatic')}
                  </label>
                </div>
                <div className="label-container">
                  <label class="input-container">
                    <input type="radio" name="radio-zone2" id="symbol-manual-on" checked={symManualOn} class="input-element" onClick={() => updateZoneDisplays({ 'arrowElement': '3', 'Mode': '1' })} />
                    {props.t('zoneDisplay.modes.ManualOn')}
                  </label>
                </div>
                <div className="label-container">
                  <label class="input-container">
                    <input type="radio" name="radio-zone2" id="symbol-manual-off" checked={symManualOff} class="input-element" onClick={() => updateZoneDisplays({ 'arrowElement': '3', 'Mode': '2' })} />
                    {props.t('zoneDisplay.modes.ManualOff')}
                  </label>
                </div>
              </div>

            </div>
          </div>

        </div>
      )
    }

    function settingsModusNumericalDisplay() {

      function updateMode(props) {


        axios.put(link + '/api/' + settingsType + '/Mode?mode=' + props.Mode, [data.id], {

        }).then((response) => {

          data.mode = parseInt(props.Mode)
          setMakeUpdate(current => !current)
        });


      }


      let numAutomatic = false
      let numDark = false
      let numXXX = false


      if (data.mode == "1") {
        numDark = true
      }

      else if (data.mode == "2") {
        numXXX = true
      }

      else {
        numAutomatic = true
      }


      return (
        <div className="settings-groups">
          <div className="settings-name"> {props.t('allgemein.modus')}</div>
          <div className="label-container">
            <label class="input-container">
              <input checked={numAutomatic} type="radio" id="num-automatic" name="radio-zone1" class="input-element" onClick={() => updateMode({ 'Mode': '0' })} />
              {props.t('zoneDisplay.modes.Automatic')}
            </label>
          </div>
          <div className="label-container">
            <label class="input-container">
              <input checked={numDark} type="radio" id="num-dark" name="radio-zone1" class="input-element" onClick={() => updateMode({ 'Mode': '1' })} />
              {props.t('numDisplay.modes.Dark')}
            </label>
          </div>
          <div className="label-container">
            <label class="input-container">
              <input checked={numXXX} type="radio" id="num-xxx" name="radio-zone1" class="input-element" onClick={() => updateMode({ 'Mode': '2' })} />
              {props.t('numDisplay.modes.XXX')}
            </label>
          </div>
        </div>
      )
    }


    function settingsTimeControl() {

      function handleChangeTC(props) {
        setSelectedOptionTC(props)
        window.timeControlModus = "tc-" + props.value
      }

      function deleteTime() {
        if (window.timeControlDelete == true) {
          window.timeControlDelete = false
          if (selectedOptionTC != undefined) {
            window.timeControlModus = "tc-" + selectedOptionTC.value
          }
        }

        else {
          window.timeControlModus = "tc-delete"
          window.timeControlDelete = true
        }

      }

      function selectTimeControl() {
        if (settingsType == "TextDisplay") {
          return (
            <div className="time-control-modus">
              <Select1 className="select" placeholder={props.t('kalender.modusAendern')} styles={customStyles} isSearchable={false} onChange={handleChangeTC}
                options={optionsTimeControlText} theme={theme => ({ ...theme, borderRadius: 3, colors: { ...theme.colors, primary: '#20a0ff' } })} />
            </div>
          )
        }
        else if (settingsType == "NumericalDisplay") {
          return (
            <div className="time-control-modus">
              <Select1 className="select" placeholder={props.t('kalender.modusAendern')} styles={customStyles} isSearchable={false} onChange={handleChangeTC}
                options={optionsTimeControlNum} theme={theme => ({ ...theme, borderRadius: 3, colors: { ...theme.colors, primary: '#20a0ff' } })} />
            </div>
          )
        }
        else {
          return (
            <div className="time-control-modus">
              <Select1 className="select" placeholder={props.t('kalender.modusAendern')} styles={customStyles} isSearchable={false} onChange={handleChangeTC}
                options={optionsTimeControl} theme={theme => ({ ...theme, borderRadius: 3, colors: { ...theme.colors, primary: '#20a0ff' } })} />
            </div>
          )
        }
      }


      return (
        <div className="settings-groups">
          <div className="settings-name">{props.t('allgemein.zeitsteuerung')} </div>

          <div className="flex-sb">
            <div className="select-switch">
              {props.t('allgemein.aktiv')}
              <label class="switch">
                <input type="checkbox" class="switch-input" />
                <span class="switch-label" data-on={props.t('allgemein.an')} data-off={props.t('allgemein.aus')}></span>
                <span class="switch-handle"></span>
              </label>
            </div>
            {selectTimeControl()}
            <div className="select-switch">
              <div style={{ textTransform: 'capitalize' }}> {props.t('allgemein.loeschen')}</div>
              <label class="switch">
                <input type="checkbox" class="switch-input" onClick={deleteTime} />
                <span class="switch-label" data-on={props.t('allgemein.an')} data-off={props.t('allgemein.aus')}></span>
                <span class="switch-handle"></span>
              </label>
            </div>


          </div>

          {updateTimeControl()}
          <button style={{ marginTop: '10px' }} class="button" > {props.t('allgemein.speichern')}</button>
        </div>
      )
    }


    function settingsOccupation() {
      return (
        <div className="settings-groups">
          <div className="settings-name">{props.t('occupancy.occupancy')}</div>
          {props.t('lot.frei')}: {data.areaValues != null ? data.areaValues.realFree : 0}; {props.t('lot.besetzt')}: {data.areaValues != null ? data.areaValues.realOccupied : 0} ({Math.ceil(data.areaValues != null ? data.areaValues.realOccupiedPercent : 0)} %)
        </div>
      )
    }

    function settingsStatus() {

      let status

      if (data.occupied == true) {
        status = props.t('lot.besetzt')
      }

      else if (data.occupied == false) {
        status = props.t('lot.frei')
      }

      if (data.defect == null) {
        status = props.t('allgemein.unbekannt')
      }

      return (
        <div className="settings-groups">
          <div className="settings-name">{props.t('allgemein.zustand')}</div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>{status}</div>
          </div>
        </div>
      )
    }

    function settingsCurrentValue() {
      return (
        <div className="settings-groups">
          <div className="settings-name">{props.t('numDisplay.aktuellerWert')}</div>
          {data.displayValueString}
        </div>
      )
    }

    function settingsReserved() {

      let styleOn
      let styleOff

      function updateReserved(props) {

        axios.put(link + '/api/' + settingsType + '/Reserved?reserved=' + props.Reserved, [data.id], {

        }).then((response) => {

          if (props.Reserved == "true") {
            data.reserved = true
          }
          else {
            data.reserved = false
          }

          setMakeUpdate(current => !current)
        });

      }

      if (data.reserved == true) {
        styleOn = { backgroundColor: '#20a0ff', color: 'white' }
        styleOff = { backgroundColor: '', color: '' }
      }

      else {
        styleOn = { backgroundColor: '', color: '' }
        styleOff = { backgroundColor: '#20a0ff', color: 'white' }
      }

      return (
        <div className="settings-groups">
          <div className="settings-name">{props.t('elementeAllgemein.areaWerte.Reserved')}</div>
          <div className="flex-sa">
            <button class="button" style={styleOn} onClick={() => updateReserved({ 'Reserved': "true" })}> {props.t('allgemein.an')}</button>
            <button class="button" style={styleOff} onClick={() => updateReserved({ 'Reserved': "false" })}> {props.t('allgemein.aus')}</button>
          </div>
        </div>
      )
    }


    if (settingsType == "Lot") {
      return (
        <div>
          <div id="settings-div">
            <div className="settings-div-name">{data.name}</div>
            <SimpleBar className="simplebar-settings">
              <div className="settings-content">
                {settingsStatus()}
                {settingsModus()}
                {/* {settingsTimeControl()} */}
                {settingsTypes()}
                {/* <SettingsTags data={data} type={settingsType}> </SettingsTags> */}
                {(data.hasLightIntensity == true) && (
                  <SettingsLightIntensity data={data} type={settingsType}> </SettingsLightIntensity>
                )}
                {settingsReserved()}
                <SettingsTimeMonitoring data={data} mode='lot'> </SettingsTimeMonitoring>
                <SettingsNoteBox data={data}> </SettingsNoteBox>
                {/* <SettingsLastActivities data={data}> </SettingsLastActivities>   */}
              </div>
            </SimpleBar>
          </div>
        </div>
      )
    }

    else if (settingsType == "ZoneDisplay") {
      return (
        <div>
          <div id="settings-div">
            <div className="settings-div-name">{data.name}</div>
            <div className="settings-content">
              <SettingsLightIntensity data={data} type={settingsType}> </SettingsLightIntensity>
              {settingsTypes()}
              {/* <SettingsTags data={data} type={settingsType}> </SettingsTags> */}
              {settingsModusZoneDisplays()}
              {/* <SettingsLastActivities data={data}> </SettingsLastActivities>   */}
            </div>
          </div>
        </div>
      )
    }

    else if (settingsType == "NumericalDisplay") {
      return (
        <div>
          <div id="settings-div">
            <div className="settings-div-name">{data.name}</div>
            <SimpleBar className="simplebar-settings">
              <div className="settings-content">
                {settingsCurrentValue()}
                <SettingsLightIntensity data={data} type={settingsType}> </SettingsLightIntensity>
                {settingsModusNumericalDisplay()}
                {settingsTypes()}
                {/* <SettingsTags data={data} type={settingsType}> </SettingsTags> */}
                {/* {settingsTimeControl()} */}
                {/* <SettingsLastActivities data={data}> </SettingsLastActivities>   */}
              </div>
            </SimpleBar>
          </div>
        </div>
      )
    }

    else if (settingsType == "TextDisplay") {
      return (
        <div>
          <div id="settings-div">
            <div className="settings-div-name">{data.name}</div>
            <SimpleBar className="simplebar-settings">
              <div className="settings-content">
                <SettingsText id={data.id}>  </SettingsText>
                {settingsModus()}
                <SettingsLightIntensity data={data} type={settingsType}> </SettingsLightIntensity>
                {settingsTypes()}
                {/* <SettingsTags data={data} type={settingsType}> </SettingsTags> */}
                {/* {settingsTimeControl()} */}
                {/* <SettingsLastActivities data={data}> </SettingsLastActivities>   */}
              </div>
            </SimpleBar>
          </div>
        </div>
      )
    }

    else if (settingsType == "Area") {
      return (
        <div>
          <div id="settings-div">
            <div className="settings-div-name">{data.name}</div>
            <SimpleBar className="simplebar-settings">
              <div className="settings-content">
                {settingsOccupation()}
                {settingsModus()}
                {settingsTypes()}
                {/* <SettingsTags data={data} type={settingsType}> </SettingsTags> */}
                {/* {settingsTimeControl()} */}
                <SettingsLightIntensity data={data} type={settingsType}> </SettingsLightIntensity>
                {settingsReserved()}
                <SettingsTimeMonitoring data={data} mode='area'> </SettingsTimeMonitoring>

                <SettingsBufferHysteresis data={data}> </SettingsBufferHysteresis>
                {(data.areaType == 5) && (
                  <SettingsCountingArea data={data}> </SettingsCountingArea>
                )}
                {/* <SettingsLocation data={data}> </SettingsLocation>  
              <SettingsLastActivities data={data}> </SettingsLastActivities>   */}
                {/* <SettingsBarrierTable data={data}> </SettingsBarrierTable >   */}
              </div>
            </SimpleBar>
          </div>
        </div>
      )
    }

    else if (settingsType == "LotCamera") {
      return (
        <div>
          <div id="settings-div" >
            <div className="settings-div-name">{data.name}</div>
            <SimpleBar className="simplebar-settings">
              <SettingsCamera data={data}> </SettingsCamera>
            </SimpleBar>
          </div>
        </div>
      )
    }


    else if (settingsType == "PercentageDisplay") {
      return (
        <div>
          <div id="settings-div" >
            <div className="settings-div-name">{data.name}</div>
            <SimpleBar className="simplebar-settings">
              <div className="settings-content">
                {settingsCurrentValue()}
                <div className="settings-groups">
                  <div className="settings-name">{props.t('allgemein.modus')}</div>
                  <div className="label-container" >
                    <label class="input-container" >
                      <input type="radio" id="mode-automatic" name="radio-car" class="input-element" />
                      {props.t('lot.modes.Automatic')}
                    </label >
                  </div>
                  <div className="label-container">
                    <label class="input-container">
                      <input type="radio" id="mode-manual-free" name="radio-car" class="input-element" />
                      {props.t('lot.modes.ManualFree')}
                    </label>
                  </div>
                  <div className="label-container">
                    <label class="input-container">
                      <input type="radio" id="mode-manual-occupied" name="radio-car" class="input-element" />
                      {props.t('lot.modes.ManualOccupied')}
                    </label>
                  </div>
                  <div className="label-container">
                    <label class="input-container">
                      <input type="radio" id="mode-manual-dark" name="radio-car" class="input-element" />
                      Manuell Dunkel
                    </label>
                  </div>
                </div>
                <SettingsLightIntensity data={data} type={settingsType}> </SettingsLightIntensity>
                {settingsTypes()}
                <div className="settings-groups">
                  <div className="settings-name">Belegt wenn &gt;= X Prozent</div>
                  <NumericInput className="input-numeric input100" mobile={"auto"} min={0} max={100} />
                  <button class="button" style={{ marginTop: '10px' }}> {props.t('allgemein.speichern')}</button>
                </div>
                <SettingsLastActivities data={data}> </SettingsLastActivities>
                <SettingsBarrierTable data={data}> </SettingsBarrierTable >
              </div>
            </SimpleBar>
          </div>
        </div>
      )
    }


    else if (settingsType == "Barrier") {
      return (
        <div>
          <div id="settings-div" >
            <div className="settings-div-name">{data.name}</div>
            <SimpleBar className="simplebar-settings">
              <div className="settings-content">
                {settingsStatus()}
                <div className="settings-groups">
                  <div className="settings-name">{props.t('allgemein.modus')}</div>
                  <div className="label-container" >
                    <label class="input-container" >
                      <input type="radio" id="mode-automatic" name="radio-car" class="input-element" />
                      {props.t('lot.modes.Automatic')}
                    </label >
                  </div>
                  <div className="label-container">
                    <label class="input-container">
                      <input type="radio" id="mode-manual-free" name="radio-car" class="input-element" />
                      Manuell offen
                    </label>
                  </div>
                  <div className="label-container">
                    <label class="input-container">
                      <input type="radio" id="mode-manual-occupied" name="radio-car" class="input-element" />
                      Manuell geschlossen
                    </label>
                  </div>
                  <div className="label-container">
                    <label class="input-container">
                      <input type="radio" id="mode-manual-dark" name="radio-car" class="input-element" />
                      Extern
                    </label>
                  </div>
                </div>
                <div className="settings-groups">
                  <div className="settings-name">Buffer</div>
                  <NumericInput className="input-numeric input100" mobile={"auto"} min={0} max={100} />
                  <button class="button" style={{ marginTop: '10px' }}> {props.t('allgemein.speichern')}</button>
                </div>
                <div className="settings-groups">
                  <div className="settings-name">Hysterese</div>
                  <NumericInput className="input-numeric input100" mobile={"auto"} min={0} max={100} />
                  <button class="button" style={{ marginTop: '10px' }}> {props.t('allgemein.speichern')}</button>
                </div>
                <div className="settings-groups">
                  <div className="settings-name">Start des Betriebs</div>
                  <NumericInput className="input-numeric input100" mobile={"auto"} min={0} max={100} />
                  <button class="button" style={{ marginTop: '10px' }}> {props.t('allgemein.speichern')}</button>
                </div>
                <div className="settings-groups">
                  <div className="settings-name">Ende des Betriebs</div>
                  <NumericInput className="input-numeric input100" mobile={"auto"} min={0} max={100} />
                  <button class="button" style={{ marginTop: '10px' }}> {props.t('allgemein.speichern')}</button>
                </div>

                <SettingsLastActivities data={data}> </SettingsLastActivities>
                <SettingsBarrierTable data={data}> </SettingsBarrierTable >
              </div>
            </SimpleBar>
          </div>
        </div>
      )
    }


    else if (settingsType == "Table") {
      return (
        <div>
          <div id="settings-div" >
            <div className="settings-div-name">{data.name}</div>
            <SimpleBar className="simplebar-settings">
              <div className="settings-content">
                {settingsStatus()}
                <div className="settings-groups">
                  <div className="settings-name">{props.t('allgemein.modus')}</div>
                  <div className="label-container" >
                    <label class="input-container" >
                      <input type="radio" id="mode-automatic" name="radio-car" class="input-element" />
                      {props.t('lot.modes.Automatic')}
                    </label >
                  </div>
                  <div className="label-container">
                    <label class="input-container">
                      <input type="radio" id="mode-manual-free" name="radio-car" class="input-element" />
                      Manuell offen
                    </label>
                  </div>
                  <div className="label-container">
                    <label class="input-container">
                      <input type="radio" id="mode-manual-occupied" name="radio-car" class="input-element" />
                      Manuell geschlossen
                    </label>
                  </div>
                </div>
                <SettingsLastActivities data={data}> </SettingsLastActivities>
                <SettingsBarrierTable data={data}> </SettingsBarrierTable >
              </div>
            </SimpleBar>
          </div>
        </div>
      )
    }

  }

}




export default withNamespaces()(Settings)
import React from 'react'
import { MenuItem, SubMenu } from "react-contextmenu";
import { withNamespaces } from 'react-i18next';
import NumericInput from 'react-numeric-input';
import { handleUpdateLightIntensity, handleUpdateMode  } from './utils.js';
import $ from 'jquery';


const modes = [
  { mode: "0", translationKey: 'numDisplay.modes.Automatic' },
  { mode: "1", translationKey: 'numDisplay.modes.Dark' },
  { mode: "2", translationKey: 'numDisplay.modes.XXX' },
  { mode: "3", translationKey: 'numDisplay.modes.Open' }
];

const NumericalDisplay = (props) => {
  
  const { contextId, contextName, userCanChange, contextLightIntensityDisplay, showSettings, getData, t, updateLightIntensity } = props;

  return (
    <div id="contextmenu" className="contextmenu">
      <SubMenu title={<div className="submenu-title"> {t('allgemein.modus')} <i className="fas fa-chevron-right submenu-icon"></i> </div>} className="submenu">
         {modes.map(({ mode, translationKey }) => (
              <React.Fragment key={mode}>
                  <MenuItem onClick={() => handleUpdateMode({ contextId, contextName, mode, type: 'NumericalDisplay' })}>
                      {t(translationKey)}
                  </MenuItem>
                  <MenuItem divider />
              </React.Fragment>
          ))}
      </SubMenu>

      <MenuItem divider />

      <SubMenu title={<div className="submenu-title"> {t('allgemein.helligkeit')} <i className="fas fa-chevron-right submenu-icon"></i> </div>} className="submenu">
        <MenuItem disabled>
          <div className="inputwrapper">
            <NumericInput id="Light-Numerical" className="react-contextmenu-numeric react-contextmenu-item" defaultValue={contextLightIntensityDisplay} mobile={"auto"} min={0} max={100} />
            <div className="inputtext contextmenu-item">%</div>
          </div>
        </MenuItem>

        <MenuItem divider />

        <MenuItem className="react-contextmenu-item-send" onClick={() => handleUpdateLightIntensity({ contextId, contextName,value: ($('#Light-Numerical').val()), type: 'NumericalDisplay' })}>
          {t('allgemein.senden')}
        </MenuItem>
      </SubMenu>

      <MenuItem divider />

      <MenuItem onClick={() => showSettings({ 'Data': [getData(contextId)], 'Type': 'NumericalDisplay' })} className="activeitem">
        {t('allgemein.einstellungen')}
      </MenuItem>

      <MenuItem divider />

      <MenuItem disabled >
        {contextName}
      </MenuItem>

      {userCanChange === 'true' &&
        <>
          <MenuItem divider />
          <MenuItem disabled >
            ID: {contextId}
          </MenuItem>
        </>
      }
    </div>
  )
}

export default withNamespaces()(NumericalDisplay);

import React from 'react'
import { MenuItem, SubMenu } from "react-contextmenu";
import { withNamespaces } from 'react-i18next';
import { handleUpdateMode, handleUpdateReserved } from './utils.js';

const modes = [
    { mode: "0", translationKey: 'lot.modes.Automatic' },
    { mode: "1", translationKey: 'lot.modes.ManualFree' },
    { mode: "2", translationKey: 'lot.modes.ManualOccupied' },
];

const reservationModes = [
    { reserved: "false", translationKey: 'allgemein.aus' },
    { reserved: "true", translationKey: 'allgemein.an' },
];

const LotDisplay = (props) => {
    const { contextId, contextName, userCanChange, addLight, showSettings, getData, contextHasCamera, t } = props;

    return (
        <div id="contextmenu" className="contextmenu" >
            {addLight()}
            <MenuItem divider />
            <SubMenu title={<div className="submenu-title"> {t('allgemein.modus')} <i className="fas fa-chevron-right submenu-icon"></i> </div>} className="submenu" >
                {modes.map(({ mode, translationKey }) => (
                    <React.Fragment key={mode}>
                        <MenuItem onClick={() => handleUpdateMode({mode, contextId, type: "Lot", contextName })}>
                            {t(translationKey)}
                        </MenuItem>
                        <MenuItem divider />
                    </React.Fragment>
                ))}
            </SubMenu>
            <MenuItem divider />
            <SubMenu title={<div className="submenu-title"> {t('allgemein.reservierung')} <i className="fas fa-chevron-right submenu-icon"></i> </div>} className="submenu">
                {reservationModes.map(({ reserved, translationKey }) => (
                    <React.Fragment key={reserved}>
                        <MenuItem onClick={() => handleUpdateReserved({reserved, contextId, type: "Lot"})}>
                            {t(translationKey)}
                        </MenuItem>
                        <MenuItem divider />
                    </React.Fragment>
                ))}
            </SubMenu>

            {contextHasCamera == true &&
                <>
                    <MenuItem divider />
                    <MenuItem onClick={() => showSettings({ 'Data': [getData(contextId)], 'Type': 'LotCamera' })} className="activeitem">
                        {t('allgemein.kamera')}
                    </MenuItem>
                </>
            }

            <MenuItem divider />
            <MenuItem onClick={() => showSettings({ 'Data': [getData(contextId)], 'Type': 'Lot' })} className="activeitem">
                {t('allgemein.einstellungen')}
            </MenuItem>
            <MenuItem divider />
            <MenuItem disabled>
                {contextName}
            </MenuItem>
            {userCanChange == 'true' &&
                <>
                    <MenuItem divider />
                    <MenuItem disabled>
                        ID: {contextId}
                    </MenuItem>
                </>
            }
        </div>
    )
}

export default withNamespaces()(LotDisplay);

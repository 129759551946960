import React, { useState, useEffect, useRef, Suspense } from 'react';
import axios from "axios";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { withNamespaces } from 'react-i18next';
import { ContextMenu, MenuItem, ContextMenuTrigger, SubMenu } from "react-contextmenu";
import $ from "jquery";
import SimpleBar from "simplebar-react";
import Draggable from 'react-draggable'

import { ReactSVG } from 'react-svg'

import { HubConnectionBuilder, JsonHubProtocol } from '@microsoft/signalr';
import jwt_decode from "jwt-decode";

import { DateTimePicker } from 'react-widgets'

import ReactLoading from 'react-loading';

import Legend from "./Components/Legend.js";
import InfoAreas from "./Components/InfoAreas.js";
import Settings from "./Components/Settings/Settings.js";
import NumericInput from 'react-numeric-input';

// Park-tree pictures
import parkimg from '../../assets/images/mgsuite/MultiStorey_carpark_24x24.png';
import parkimg2 from '../../assets/images/mgsuite/Carpark_24x24.png';
import parkimg3 from '../../assets/images/mgsuite/Area-Group_24x24.png';
import parkimg4 from '../../assets/images/mgsuite/SSD_Area_24x24.png';

import svgbackground from '../../assets/images/mgsuite/multiguidelogo.png'

// Collapse/expand pictures
import dd1Icon from '../../assets/images/mgsuite/collapse.png';
import dd2Icon from '../../assets/images/mgsuite/expand.png';


import updateMode from "./Functions/updateMode.js";
import updateZoneDisplays from "./Functions/updateZoneDisplays.js";
import updateReserved from "./Functions/updateReserved.js";
import updateLightIntensity from "./Functions/updateLightIntensity.js";
import updateTypes from "./Functions/updateTypes.js";

import ZoneDisplay from './Components/contextLotsDisplays/ZoneDisplay.js';
import LotDisplay from './Components/contextLotsDisplays/LotDisplay.js';
import NumericalDisplay from './Components/contextLotsDisplays/NumericalDisplay.js';
import TextDisplay from './Components/contextLotsDisplays/TextDisplay.js';
import { handleUpdateLightIntensity } from './Components/contextLotsDisplays/utils.js';

let dd1 = dd1Icon
let dd2 = dd2Icon

//colors

let occupColor1 = "#008DD2"
let occupColor2 = "#D9DADA"

let occupResColor1 = "#008DD2"
let occupResColor2 = "#FFED00"

let occupExcColor1 = "#E5097F"
let occupExcColor2 = "#D9DADA"

let defColor1 = "#E31E24"
let defColor2 = "#D9DADA"

let freeColor1 = null
let freeColor2 = null

let unknownColor1 = "white"
let unknownColor2 = "#D9DADA"

let freeResColor1 = "#FFED00"
let freeResColor2 = "#D9DADA"

let defResColor1 = "#E31E24"
let defResColor2 = "#FFED00"

let mainMap = null
// let mainMap=<Schild></Schild>

//park-tree & table 

let parkName
let mainHasSvg = false
let oneParkingLot = false

let lots = []
let displays = []
let mapdata = []
let mainparkinglots = []
let subparkinglots = []
let areaparkinglots = []
let detailedAreaData = []
let sites = []
let statefirst = {}

let statemain = {}
let statesub = {}
let statemainarea = {}
let statesubarea = {}

//map 
let maps = {}
let currentmap
let oldmap
let svgshow = svgbackground
let wheelstep = 20

//path 
let activePark
let activePark2
let currentarea

//park-tree context 
let treeContextData
let treeContextId
let treeContextName

//full-screen
let tabletselectmode = false
let areaTabletOpen = false
let treeTabletOpen = false
let fsmodeon = false

let supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints


let hoverAreaContainer
let hoverAreaId


//hover map variables

//common for all
let mapHoverName
let mapHoverLight
let mapHoverLightDefault

//lots
let mapHoverStatus
let mapHoverLicense
let mapHoverRemark
let mapHoverType
let mapHoverArea
let mapHoverReserved
let mapHoverTime
let mapHoverTime2
let mapHoverDate
let mapHaveTime
let mapHoverModus
let mapTimeLimit
let mapHaveTimeLimit
let mapHoverGeoLat
let mapHoverGeoLon


//zonedisplays

let zonedisplay = false

let mapLeftArrow
let mapAheadArrow
let mapRightArrow
let mapXSign

let mapLeftArrowMode
let mapAheadArrowMode
let mapRightArrowMode
let mapXSignMode

//textdisplays

let mapDisplayValue

//contextmenu 

let contextData
let contextName
let contextMode
let contextReserved
let contextId
let contextOccupied
let contextLightIntensityLots
let contextHasCamera = false
let contextTimeDD
let contextTimeHH
let contextTimeMM

//contextmenu displays
let contextType
let contextGroup = []
let contextLightIntensityDisplay

//selection

let selectedcars = []
let selectedCarsAll = []
let carsselected = false
let allCarsHaveLightIntensity

// Setings
let settingsData
let settingsType = "Lot"



// Time control
window.timeControlModus = ""
window.timeControlDelete = false


// Function to move hover info

function showInfo(id) {

    hoverAreaId = id

    let e = window.event;
    let posX = e.clientX;
    let posY = e.clientY;
    let addToTop
    let addToLeft

    if (posX > (window.innerWidth - ($('#showinfo').width() / 2) - 5)) {
        addToLeft = window.innerWidth - $('#showinfo').width() - 5
    }

    else {
        addToLeft = posX - ($('#showinfo').width() / 2)
    }

    if (posY > window.innerHeight / 2 + 30) {
        addToTop = posY - ($('#showinfo').height() + 50)
    }

    else {
        addToTop = posY + 50
    }

    if (window.innerWidth < 813 && window.innerHeight < 900) {
        addToLeft = '50%'
        addToTop = '50%'
        $('#showinfo').css('transform', 'translateX(-50%) translateY(-50%)')
    }

    hoverAreaContainer = {
        top: addToTop,
        left: addToLeft
    };

}


let pageLoaded = false
let previousAreaId = null
let tokenExpired = null

const Belegung = (props) => {
    
    let link = localStorage.getItem('link')
    let types = JSON.parse(localStorage.getItem('lotTypes'))

    const [loaderShow, setLoaderShow] = useState(false);
    const [token, setToken] = useState(localStorage.getItem('JWT'));
    const [userCanChange, setUserCanChange] = useState(localStorage.getItem('userCanChange'));
    const [connection, setConnection] = useState(null);
    const [message, setMessage] = useState({});
    const [currentAreaId, setCurrentAreaId] = useState(null)

    useEffect(() => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('JWT');
        setToken(localStorage.getItem('JWT'))
    }, [localStorage.getItem('JWT')]);

    // Socket functions

    function updateSvg(socketData) {

        if ($('.svg').find('svg').length != 0) {

            let color1
            let color2

            let parkplace
            let display

            if (socketData.displayType == "TextDisplay") {

                let config

                if (socketData.mode == 0) {
                    if (socketData.currentFreeValue > 0) {
                        config = 1
                    }
                    else {
                        config = 2
                    }
                }
                else {
                    config = socketData.mode
                }



                axios.get(link + '/api/TextDisplay/Config?elementId=' + socketData.id, {


                }).then((response) => {

                    let data = response.data

                    for (let j = 0; j < data.length; j++) {

                        if (data[j].configType == config) {

                            let orientation = data[j].orientation
                            let text = data[j].text
                            let displayMode = data[j].displayMode
                            let colorB = data[j].colorB
                            let colorG = data[j].colorG
                            let colorR = data[j].colorR
                            let fontSize = data[j].fontSize
                            let fontName = data[j].fontName

                            let item = document.getElementById(socketData.name)

                            $(item).attr('fill', "rgb(" + colorR + "," + colorG + "," + colorB + ")")

                            if (displayMode == 0) {
                                $(item).text(socketData.currentFreeValue)
                            }

                            else {
                                $(item).text(text).attr("font-size", fontSize).attr("style", "font-family:" + fontName)
                            }

                            let boxWidth = item.previousElementSibling.getBBox().width
                            let itemWidth = item.getComputedTextLength().toFixed(1)

                            if (itemWidth <= Math.ceil(boxWidth)) {

                                if (orientation == 0) {
                                    $(item).attr('x', 1).attr('text-anchor', 'start')
                                }

                                else if (orientation == 1) {
                                    $(item).attr('x', (boxWidth - itemWidth) / 2).attr('text-anchor', 'start')
                                }

                                else {
                                    $(item).attr('x', boxWidth - itemWidth).attr('text-anchor', 'start')
                                }


                            }


                            else {

                                if (displayMode != 0) {

                                    $(item).empty();

                                    let tL = text.length
                                    let fS = 30
                                    let dY = 0.7
                                    let x = null

                                    if (tL > 5) {
                                        $(item).attr('x', 1).attr('text-anchor', 'start')
                                        x = 3
                                    }

                                    if (tL >= 4 && tL <= 6) {
                                        fS = 17; dY = 1
                                    }
                                    else if (tL > 6 && tL <= 14) {
                                        fS = 13; dY = 0.5
                                    }
                                    else if (tL > 14 && tL <= 16) {
                                        fS = 12; dY = 0.45
                                    }
                                    else if (tL > 16 && tL <= 18) {
                                        fS = 11; dY = 0.4
                                    }
                                    else if (tL > 18 && tL <= 33) {
                                        fS = 9; dY = 0.35
                                    }
                                    else if (tL > 33 && tL <= 50) {
                                        fS = 8; dY = 0.3
                                    }
                                    else if (tL > 50 && tL <= 75) {
                                        fS = 7; dY = 0.25
                                    }
                                    else if (tL > 75 && tL <= 100) {
                                        fS = 6; dY = 0.20
                                    }
                                    else if (tL > 100) {
                                        fS = 5; dY = 0.15
                                    }

                                    let arr = text.split(/(\s+)/)

                                    $(item).attr("font-size", fS)

                                    let str = ""
                                    let oldStr = ""
                                    let fullStr = ""
                                    let lastNode = false

                                    for (let i = 0; i < arr.length; i++) {

                                        str += arr[i]
                                        fullStr += arr[i]

                                        if (str.charAt(0) === " ") {
                                            str = str.substring(1);
                                        }

                                        let node = document.createElementNS("http://www.w3.org/2000/svg", "tspan");

                                        node.setAttribute('y', 6)
                                        node.setAttribute('x', x)
                                        node.setAttribute('dy', dY + "em")

                                        if (fullStr.length > 170) {
                                            str += "..."
                                            oldStr = oldStr + "..."
                                            lastNode = true
                                        }
                                        node.innerHTML = str;

                                        item.appendChild(node)

                                        let nodeWidth = node.getComputedTextLength().toFixed(1)

                                        if (arr.length == i + 1) {
                                            lastNode = true
                                        }

                                        if (nodeWidth >= Math.ceil(boxWidth) || lastNode == true) {

                                            if (nodeWidth - boxWidth >= 3 || lastNode == true) {

                                                if (lastNode == true && nodeWidth - boxWidth >= 3) {
                                                    if (arr.length == 1) {
                                                        item.appendChild(node)
                                                        return
                                                    }
                                                    node.innerHTML = oldStr
                                                    item.removeChild(node)
                                                    item.appendChild(node)
                                                    dY = dY + 1
                                                    let nodeLast = document.createElementNS("http://www.w3.org/2000/svg", "tspan");
                                                    nodeLast.setAttribute('y', 6)
                                                    nodeLast.setAttribute('x', x)
                                                    nodeLast.setAttribute('dy', dY + "em")
                                                    nodeLast.innerHTML = arr[i]
                                                    item.appendChild(nodeLast)
                                                    return
                                                }
                                                else if (lastNode == true) {
                                                    node.innerHTML = str
                                                }
                                                else {
                                                    node.innerHTML = oldStr
                                                    str = arr[i]
                                                }

                                            }
                                            else {
                                                str = ""
                                            }




                                            item.removeChild(node)
                                            item.appendChild(node)
                                            dY = dY + 1

                                        }

                                        else {
                                            item.removeChild(node)
                                        }

                                        oldStr = str

                                        if (fullStr.length > 170) {
                                            return
                                        }
                                    }

                                }

                            }

                        }







                    }


                })

                    .catch(error => {

                    })

            }


            //displays


            if (socketData.type == "Display") {

                display = document.getElementById(socketData.name)

                //Zone

                if (socketData.displayType == "ZoneDisplay") {

                    if (socketData.xSign == true) {
                        $(display).find('path').eq(7).attr('fill', 'red')
                        $(display).find('path').eq(8).attr('fill', 'red')
                        $(display).find('polygon').eq(6).attr('fill', 'red')
                        $(display).find('polygon').eq(7).attr('fill', 'red')
                    }

                    else {
                        $(display).find('path').eq(7).attr('fill', 'none')
                        $(display).find('path').eq(8).attr('fill', 'none')
                        $(display).find('polygon').eq(6).attr('fill', 'none')
                        $(display).find('polygon').eq(7).attr('fill', 'none')
                    }

                    if (socketData.aheadArrow == true) {
                        $(display).find('path').eq(1).attr('fill', '#00FF00')
                        $(display).find('path').eq(5).attr('fill', '#00FF00')
                        $(display).find('polygon').eq(0).attr('fill', '#00FF00')
                        $(display).find('polygon').eq(4).attr('fill', '#00FF00')
                    }

                    else {
                        $(display).find('path').eq(1).attr('fill', 'none')
                        $(display).find('path').eq(5).attr('fill', 'none')
                        $(display).find('polygon').eq(0).attr('fill', 'none')
                        $(display).find('polygon').eq(4).attr('fill', 'none')
                    }

                    if (socketData.leftArrow == true && socketData.aheadArrow == true) {
                        $(display).find('path').eq(2).attr('fill', '#00FF00')
                        $(display).find('path').eq(4).attr('fill', '#00FF00')
                        $(display).find('polygon').eq(1).attr('fill', '#00FF00')
                        $(display).find('polygon').eq(3).attr('fill', '#00FF00')
                    }

                    else if (socketData.leftArrow == true && socketData.aheadArrow == false) {
                        $(display).find('path').eq(2).attr('fill', '#00FF00')
                        $(display).find('path').eq(4).attr('fill', '#00FF00')
                        $(display).find('path').eq(3).attr('fill', '#00FF00')
                        $(display).find('polygon').eq(1).attr('fill', '#00FF00')
                        $(display).find('polygon').eq(3).attr('fill', '#00FF00')
                        $(display).find('polygon').eq(2).attr('fill', '#00FF00')
                    }

                    if (socketData.rightArrow == true && socketData.aheadArrow == true) {
                        $(display).find('path').eq(3).attr('fill', '#00FF00')
                        $(display).find('path').eq(6).attr('fill', '#00FF00')
                        $(display).find('polygon').eq(2).attr('fill', '#00FF00')
                        $(display).find('polygon').eq(5).attr('fill', '#00FF00')
                    }


                    else if (socketData.rightArrow == true && socketData.aheadArrow == false) {
                        $(display).find('path').eq(3).attr('fill', '#00FF00')
                        $(display).find('path').eq(6).attr('fill', '#00FF00')
                        $(display).find('path').eq(2).attr('fill', '#00FF00')
                        $(display).find('polygon').eq(2).attr('fill', '#00FF00')
                        $(display).find('polygon').eq(5).attr('fill', '#00FF00')
                        $(display).find('polygon').eq(1).attr('fill', '#00FF00')
                    }


                    if (socketData.leftArrow != true) {
                        $(display).find('path').eq(4).attr('fill', 'none')
                        $(display).find('polygon').eq(3).attr('fill', 'none')

                        if (socketData.aheadArrow == true) {
                            $(display).find('path').eq(2).attr('fill', 'none')
                            $(display).find('polygon').eq(1).attr('fill', 'none')
                        }

                    }

                    if (socketData.rightArrow != true) {
                        $(display).find('path').eq(6).attr('fill', 'none')
                        $(display).find('polygon').eq(5).attr('fill', 'none')

                        if (socketData.aheadArrow == true) {
                            $(display).find('path').eq(3).attr('fill', 'none')
                            $(display).find('polygon').eq(2).attr('fill', 'none')
                        }
                    }

                    if (socketData.leftArrow != true && socketData.rightArrow != true && socketData.aheadArrow != true) {
                        $(display).find('path').eq(2).attr('fill', 'none')
                        $(display).find('path').eq(3).attr('fill', 'none')
                        $(display).find('polygon').eq(1).attr('fill', 'none')
                        $(display).find('polygon').eq(2).attr('fill', 'none')
                    }






                }


                //for hover on numerical display (is a tspan in svg, need to select the parent)

                if (socketData.displayType == "NumericalDisplay") {

                    if (socketData.mode == 1) {
                        $(display).attr('fill', 'none')
                    }

                    else if (socketData.mode == 2) {
                        $(display).text("XXX").attr('fill', 'red')
                    }

                    else {
                        $(display).text((socketData.displayValueNumeric)).attr('fill', '#00FF00')
                    }

                    if (document.getElementById(socketData.name) != null) {
                        display = $(document.getElementById(socketData.name)).parent().parent()
                    }
                }

            }

            //parkplaces

            else {

                parkplace = document.getElementById(socketData.name)

                //get IDs for converted svg maps that take the zeros out of the IDs
                if (parkplace == null && socketData.name.slice(-1) == "0") {
                    parkplace = document.getElementById(socketData.name.slice(0, -1))
                    if (parkplace == null && socketData.name.slice(-2) == "00") {
                        parkplace = document.getElementById(socketData.name.slice(0, -2))
                    }
                    if (parkplace == null && socketData.name.slice(-3) == "000") {
                        parkplace = document.getElementById(socketData.name.slice(0, -4))
                    }
                }



                if (currentarea == socketData.parentAreaName) {
                    $(parkplace).find('rect').eq(0).attr("stroke", "red").attr("stroke-opacity", "1.0").attr("stroke-width", "70")
                }

                else (
                    $(parkplace).find('rect').eq(0).attr("stroke", "null").attr("stroke-opacity", "null").attr("stroke-width", "null")
                )

                if (selectedcars.length != 0) {
                    for (let x = 0; x < selectedcars.length; x++) {
                        if (socketData.id == selectedcars[x]) {
                            $(parkplace).find('rect').eq(0).attr("stroke", "purple").attr("stroke-opacity", "1.0").attr("stroke-width", "70")
                        }
                    }
                }



                if (socketData.occupied == true) {
                    color1 = occupColor1
                    color2 = occupColor2
                }

                else if (socketData.occupied != true) {
                    color1 = freeColor1
                    color2 = freeColor2
                }

                if (socketData.defect == true) {
                    color1 = defColor1
                    color2 = defColor2
                }

                if (socketData.occupied == true && socketData.reserved == true) {
                    color1 = occupResColor1
                    color2 = occupResColor2
                }

                if (socketData.occupied == true && socketData.exceeded == true) {
                    color1 = occupExcColor1
                    color2 = occupExcColor2
                }

                if (socketData.occupied == false && socketData.reserved == true) {
                    color1 = freeResColor1
                    color2 = freeResColor2
                }

                if (socketData.reserved == true && socketData.defect == true) {
                    color1 = defResColor1
                    color2 = defResColor2
                }

                if (socketData.defect == null) {
                    color1 = unknownColor1
                    color2 = unknownColor2
                }


                if (color1 == null && color2 == null || color1 == "white" && color2 == "white") {
                    $(parkplace).find('path').css('fill-opacity', "0.0").css('stroke-opacity', "0.0")
                }

                else {
                    $(parkplace).find('path').css('fill-opacity', "1.0").css('stroke-opacity', "1.0")
                    $(parkplace).find('path').eq(0).css('fill', color1)
                    $(parkplace).find('path').eq(3).css('fill', color2)
                }



                if (socketData.mode != 0) {

                    let view
                    if ($("tspan:contains(" + socketData.name + ")").parent()[0]) {
                        view = ($("tspan:contains(" + socketData.name + ")").parent()[0].getAttribute("font-size"))
                    }
                    else {
                        view = 27
                    }

                    let strokewidth = 2 * (view / 27)

                    if (socketData.mode == 1) {

                        $("tspan").filter(function () {
                            return $(this).text() === socketData.name;
                        }).attr("fill", "green").attr("stroke", "green").attr("stroke-width", strokewidth)

                    }
                    else if (socketData.mode == 2) (

                        $("tspan").filter(function () {
                            return $(this).text() === socketData.name;
                        }).attr("fill", "red").attr("stroke", "red").attr("stroke-width", strokewidth)

                    )
                }

                else (
                    $("tspan:contains(" + socketData.name + ")").attr("fill", "").attr("stroke-width", "0")
                )


                if (socketData.type == "Lot") {

                    let r
                    let g
                    let b

                    if (socketData.hasOwnProperty('CurrentStateColorForFree')) {
                        r = socketData.CurrentStateColorForFree.r
                        g = socketData.CurrentStateColorForFree.g
                        b = socketData.CurrentStateColorForFree.b
                    }

                    else {
                        r = socketData.lotType.colorFree.r
                        g = socketData.lotType.colorFree.g
                        b = socketData.lotType.colorFree.b
                    }

                    $(parkplace).find('rect').eq(0).attr("fill", "rgb(" + r + "," + g + "," + b + ")").attr("fill-opacity", "0.3")
                }

            }

        }
    }


    useEffect(() => {
        if (connection) {
            connection.stop()
        }
        const newConnection = new HubConnectionBuilder()
            .withUrl(link + '/ws/elementHub',
                {
                    accessTokenFactory: () =>
                        token,
                }
            )
            .withAutomaticReconnect()
            .withHubProtocol(new JsonHubProtocol())
            .build();


        setConnection(newConnection);


    }, [token]);

    useEffect(() => {
        if (connection) {
            connection.start()

                .then(result => {

                    connection.invoke('RegisterAreaUpdates', "");

                    connection.on("ElementChanged", (type, message) => {
                        let data = JSON.parse(message)
                        setMessage({ 'type': type, 'data': data });
                    });


                })
                .catch(function (error) {
                })
        }

        return () => {
            if (connection) {
                connection.stop()
            }
        }


    }, [connection]);


    useEffect(() => {

        if (connection) {
            if (previousAreaId != null) {
                try {
                    connection.invoke('UnRegisterElementUpdatesForArea', previousAreaId);
                }
                catch (error) {

                }

            }
            if (currentAreaId != null) {
                try {
                    connection.invoke('RegisterElementUpdatesForArea', currentAreaId);
                }
                catch (error) {

                }
            }
        }

        previousAreaId = currentAreaId



    }, [currentAreaId]);


    useEffect(() => {

        if (message.type) {

            if (message.type == "AreaModel") {

                for (let i = 0; i < detailedAreaData.length; i++) {
                    if (detailedAreaData[i].id == message.data.id) {
                        detailedAreaData[i] = message.data
                    }
                }

                for (let i = 0; i < mainparkinglots.length; i++) {
                    if (mainparkinglots[i].id == message.data.id) {
                        mainparkinglots[i] = message.data
                    }
                }

                for (let i = 0; i < subparkinglots.length; i++) {
                    if (subparkinglots[i].id == message.data.id) {
                        subparkinglots[i] = message.data
                    }
                }

                for (let i = 0; i < areaparkinglots.length; i++) {
                    if (areaparkinglots[i].id == message.data.id) {
                        areaparkinglots[i] = message.data
                    }
                }

            }



            else {

                for (let i = 0; i < mapdata.length; i++) {
                    if (mapdata[i].id == message.data.id) {
                        let type
                        let displayType

                        if (mapdata[i].type != null) {
                            type = mapdata[i].type
                        }

                        if (mapdata[i].displayType != null) {
                            displayType = mapdata[i].displayType
                        }

                        mapdata[i] = message.data

                        if (type != null) {
                            mapdata[i].type = type
                        }

                        if (displayType != null) {
                            mapdata[i].displayType = displayType
                        }

                        updateSvg(message.data)
                    }
                }

            }


        }



    }, [message]);


    // States

    const [showMap, setMap] = useState(maps);
    const [changeMap, setChangeMap] = useState(false);
    const [resetZoom, setResetZoom] = useState(false);
    const [showLevelFirst, setShowLevelFirst] = useState(statefirst);
    const [showLevelMain, setShowLevelMain] = useState(statemain);
    const [showLevelSub, setShowLevelSub] = useState(statesub);
    const [showAreaMain, setShowAreaMain] = useState(statemainarea);
    const [showAreaSub, setShowAreaSub] = useState(statesubarea);
    const [inHover, setHover] = useState(false);
    const [showTreeContext, setHoverTreeContext] = useState(false);
    const [inHoverContext, setHoverContext] = useState(false);
    const [legendShow, setLegendShow] = useState(false);
    const [settingsShow, setSettingsShow] = useState(false);
    const [showHoverLots, setHoverLots] = useState(false);
    const [showHoverLotsContext, setHoverLotsContext] = useState(false);
    const [initialUpdate, setInitialUpdate] = useState(false);
    const [makeUpdate, setMakeUpdate] = useState(false);

    const [popUpShow, setPopUpShow] = useState(false);

    // Refs
    const legendContainer = useRef(null);
    const settingsContainer = useRef(null);
    const treeContainer = useRef(null);
    const treeContextContainer = useRef(null);
    const areaContainer = useRef(null);
    const lotsContainer = useRef(null);
    const settingsSelect = useRef(null);


    // Of map returned to original place remove the interval
    if (resetZoom == true) {

        let mapReset = setInterval(stopMapReset, 100)

        function stopMapReset() {

            // if($('.react-transform-element')[0] != null) 

            // {
            //   if ( ($('.react-transform-element')[0].getAttribute("style")=='transform: translate(0px, 0px) scale(1);') || 
            //   ($('.react-transform-element')[0].getAttribute("style")=='transform: translate(0px) scale(1);')) 

            //   {
            //     setResetZoom(false)
            //     clearInterval(mapReset)
            //   }
            // }

            setResetZoom(false)
            clearInterval(mapReset)

        }


    }

    // Restart map and path after changing of pages
    if (changeMap == false) {
        svgshow = svgbackground
        parkName = null
        currentmap = null
        activePark = null
        activePark2 = null
    }



    function delay(timer) {
        return new Promise(resolve => {
            setTimeout(function () {
                if (localStorage.getItem('JWT')) {
                    let decoded = jwt_decode(localStorage.getItem('JWT'));
                    let diff = decoded.exp * 1000 - Date.now()

                    if (diff <= 0) {
                        tokenExpired = true
                    }
                    else {
                        tokenExpired = false
                    }

                }

                resolve()
            }, timer);
        });
    };


    useEffect(() => {

        tokenExpired = null

        if (pageLoaded) {
            setLoaderShow(false)
        }
        else {
            setLoaderShow(true)
        }


        async function getAreaData() {
            while (tokenExpired == null || tokenExpired == true)
                await delay(50);

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('JWT');

            axios.get(link + '/api/Area/sites')
                .then((response) => {

                    sites = []
                    sites = response.data

                    for (let i = 0; i < sites.length; i++) {
                        statefirst[i] = true
                    }

                    setMakeUpdate(current => !current)

                })

            axios.get(link + '/api/Area')
                .then((response) => {

                    detailedAreaData = []
                    mainparkinglots = []
                    subparkinglots = []
                    areaparkinglots = []

                    detailedAreaData.push(response.data)
                    detailedAreaData = detailedAreaData[0]

                    for (let i = 0; i < response.data.length; i++) {


                        if (response.data[i].areaType == 2) {
                            mainparkinglots.push(response.data[i])

                        }

                        if (response.data[i].areaType == 3) {

                            subparkinglots.push(response.data[i])

                        }

                        if (response.data[i].areaType == 4 || response.data[i].areaType == 5) {

                            areaparkinglots.push(response.data[i])


                        }

                    }

                    for (let i = 0; i < areaparkinglots.length; i++) {
                        for (let j = 0; j < mainparkinglots.length; j++) {
                            if (areaparkinglots[i].parentElement == mainparkinglots[j].name) {
                                subparkinglots.push(areaparkinglots[i])
                            }
                        }

                    }




                    for (let i = 0; i < mainparkinglots.length; i++) {
                        if (oneParkingLot) {
                            statemain[i] = true
                        }
                        else {
                            statemain[i] = false
                        }

                        statemainarea[i] = true
                    }

                    for (let i = 0; i < subparkinglots.length; i++) {
                        statesub[i] = false
                        statesubarea[i] = false
                    }


                    mainparkinglots.sort((a, b) => a.areaTreeSortOrder - b.areaTreeSortOrder);

                    pageLoaded = true
                    setLoaderShow(false)



                })

                .catch(error => {

                })

        }


        getAreaData()


    }, []);

    // Fullscreen functions

    function startMapFS() {
        $('.bodyoverlay').addClass('bodyoverlayFS')
        $('#svgmap').addClass('mapFS')
        $('#firstrow').addClass('firstrowFS')
        $('#treeTablet').addClass('treeTabletFS')
        $('#legend').addClass('legendFS')
        $('#parkcurrent').addClass('parkcurrentFS')
        $('#parktreeall').addClass('parktreeallFS').css('left', '-400px')
        $('.svg').addClass('svgFS')
        $('.pagewrapper').addClass('pagewrapperFS')
        wheelstep = 350
        setResetZoom(true)
        fsmodeon = true
    }

    function cancelMapFS() {
        treeTabletOpen = false
        $(".bodyoverlay").removeClass('bodyoverlayFS').hide()
        $('#svgmap').removeClass('mapFS')
        $('#firstrow').removeClass('firstrowFS')
        $('#treeTablet').removeClass('treeTabletFS')
        $('#legend').removeClass('legendFS')
        $('#parkcurrent').removeClass('parkcurrentFS')
        $('#parktreeall').removeClass('parktreeallFS').css('left', 'unset')
        $('.svg').removeClass('svgFS')
        $('.pagewrapper').removeClass('pagewrapperFS')
        wheelstep = 20
        setResetZoom(true)
        fsmodeon = false
    }

    // Fullscreen function
    function toggleFullscreen() {
        if (
            !document.fullscreenElement &&
    /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(
                    Element.ALLOW_KEYBOARD_INPUT
                );
            }

            startMapFS()


        } else {

            cancelMapFS()

            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    document.addEventListener("fullscreenchange", function () {
        if (
            !document.fullscreenElement &&
            !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            cancelMapFS()
        }

    }
    );


    // Settings functions
    function showSettings(data) {

        settingsData = data.Data
        settingsType = data.Type

        if (settingsType == "Area") {
            if (settingsData[0].areaValues == null) {
                return
            }
        }

        setSettingsShow(true)
        $('.contextmenu').hide()
        $('.react-contextmenu').hide()

        //can be buggy, all types must have time control if they are not zone type

        // if (data.Type!='ZoneDisplay') {
        //   let interval= setInterval(time,1)
        //   function time() {
        //     timeFunctions()
        //     if ($('#time-map').children().length!=0) {
        //        clearInterval(interval)
        //   }
        //   }
        // }

        // window.open('/settingsOccupancy/id=/'+settingsData+'type=/'+settingsType,'mywin','width=500,height=500');
        // data={settingsData} type={settingsType}

        setMakeUpdate(current => !current)
    }


    // Contextmenu for park tree

    const treeContextMenu = () => {

        return (
            <ContextMenu id="treeContext">
                <SubMenu title={<div className="submenu-title"> {props.t('allgemein.helligkeit')} <i class="fas fa-chevron-right submenu-icon"></i></div>} className="submenu">
                    <MenuItem disabled>
                        <div className="inputwrapper">
                            <NumericInput id="Light-Tree" className="react-contextmenu-numeric react-contextmenu-item" defaultValue={0} mobile={"auto"} min={0} max={100} />
                            <div class="inputtext contextmenu-item">%</div>
                        </div>
                    </MenuItem>
                    <MenuItem divider />
                    <MenuItem className="react-contextmenu-item-send" onClick={() => updateLightIntensity({ 'Id': [treeContextId], 'Name': treeContextName, 'Value': ($('#Light-Tree').val()), 'Type': 'Area' })}>
                        {props.t('allgemein.senden')}
                    </MenuItem>
                </SubMenu>
                {/* <MenuItem divider /> 
  <SubMenu title= {<div  className="submenu-title"> {props.t('elementeAllgemein.zeitueberwachung')} <i class="fas fa-chevron-right submenu-icon"></i></div>} className="submenu">
  <MenuItem onClick={() =>  updateTimeMonitoring({'Id':[treeContextId],'Name':treeContextName,'Mode':'Off', 'Type':'Tree'})}>
  {props.t('allgemein.aus')}
  </MenuItem>
  <MenuItem divider />
<MenuItem disabled>
 <div className="inputwrapper">
 <NumericInput id="TimeConTree-dd" className="react-contextmenu-numeric react-contextmenu-item" value={0} mobile={"auto"} min={0} max={99} />
 <div class="inputtext contextmenu-item">dd</div>
 </div>
</MenuItem>
<MenuItem divider />
<MenuItem disabled>
<div className="inputwrapper">
<NumericInput id="TimeConTree-hh" className="react-contextmenu-numeric react-contextmenu-item" value={0} mobile={"auto"} min={0} max={23} />
<div class="inputtext contextmenu-item">hh</div>
</div>
</MenuItem>
<MenuItem divider />
<MenuItem disabled >
<div className="inputwrapper">
<NumericInput id="TimeConTree-mm" className="react-contextmenu-numeric react-contextmenu-item" value={0} mobile={"auto"} min={0} max={59} />
<div class="inputtext contextmenu-item">mm</div>
</div>
</MenuItem>
<MenuItem divider />
<MenuItem className="react-contextmenu-item-send" onClick={() =>  updateTimeMonitoring({'Id':[treeContextId],'Name':treeContextName, 'Value':[$('#TimeConTree-dd').val(),$('#TimeConTree-hh').val(),$('#TimeConTree-mm').val()],'Mode':'On', 'Type':'Tree'})}> 
{props.t('allgemein.senden')}
  </MenuItem>
  </SubMenu>  */}
                <MenuItem divider />
                <SubMenu title={<div className="submenu-title"> {props.t('allgemein.modus')} <i class="fas fa-chevron-right submenu-icon"></i></div>} className="submenu">
                    <MenuItem onClick={() => updateMode({ 'Id': [treeContextId], 'Name': treeContextName, 'Mode': '0', 'Type': 'Area' })}>
                        {props.t('lot.modes.Automatic')}
                    </MenuItem>
                    <MenuItem divider />
                    <MenuItem onClick={() => updateMode({ 'Id': [treeContextId], 'Name': treeContextName, 'Mode': '1', 'Type': 'Area' })}>
                        {props.t('lot.modes.ManualFree')}
                    </MenuItem>
                    <MenuItem divider />
                    <MenuItem onClick={() => updateMode({ 'Id': [treeContextId], 'Name': treeContextName, 'Mode': '2', 'Type': 'Area' })}>
                        {props.t('lot.modes.ManualOccupied')}
                    </MenuItem>
                </SubMenu>
                <MenuItem divider />
                <SubMenu title={<div className="submenu-title"> {props.t('allgemein.reservierung')} <i class="fas fa-chevron-right submenu-icon"></i></div>} className="submenu">
                    <MenuItem onClick={() => updateReserved({ 'Id': [treeContextId], 'Reserved': false, 'Type': 'Area' })}>
                        {props.t('allgemein.aus')}
                    </MenuItem>
                    <MenuItem divider />
                    <MenuItem onClick={() => updateReserved({ 'Id': [treeContextId], 'Reserved': true, 'Type': 'Area' })}>
                        {props.t('allgemein.an')}
                    </MenuItem>
                </SubMenu>
                <MenuItem divider />
                <MenuItem onClick={() => showSettings({ 'Data': [treeContextData], 'Type': 'Area' })} className="activeitem">
                    {props.t('allgemein.einstellungen')}
                </MenuItem>
                {/* <MenuItem divider /> */}
                {/* <MenuItem disabled >
    ID: {currentIdSett}
  </MenuItem> */}
                <MenuItem divider />
                <MenuItem disabled >
                    {treeContextName}
                </MenuItem>
                {userCanChange == 'true' &&
                    <>
                        <MenuItem divider />
                        <MenuItem disabled >
                            ID: {treeContextId}
                        </MenuItem>
                    </>
                }
            </ContextMenu>

        );
    }

    // Close settings view if clicked outside

    $(document).on('click', function (e) {


        //when tree open (phone, tablets, FS-Mode), close only if clicked outside of it, stay open if contextmenu opened
        if (treeTabletOpen == true && treeContainer.current && !treeContainer.current.contains(e.target) && !$(e.target).is('#treeTablet') && !$(e.target).is('.react-contextmenu-item')
            && !$(e.target).is('.submenu-title')) {
            treeTabletOpen = false
            $(".bodyoverlay").hide()
            $("#parktreeall").animate({ "left": "-400px" }, "fast")
        }

        //when area open (phone, tablets), close only if clicked outside of it, stay open if contextmenu opened
        if (areaTabletOpen == true && areaContainer.current && !areaContainer.current.contains(e.target) && !$(e.target).is('#areasTablet')) {
            areaTabletOpen = false
            $(".bodyoverlay").hide()
            $(".table1").animate({ "right": "-400px" }, "fast")
        }

        if (legendContainer.current && !legendContainer.current.contains(e.target) && !$(e.target).is('#legend')) {
            setLegendShow(false)
        }

        if (settingsContainer.current && !settingsContainer.current.contains(e.target) && !$(e.target).is('.react-contextmenu-item') && !$(e.target).is('.settings-item') && !$(e.target).hasClass('select-icon')
            && !$(e.target).children().hasClass('settings-item') && !$(e.target).parents('.rc-time-picker-panel').length == 1 && !$(e.target).parent().hasClass('css-ykwh22')
            && !$(e.target).parent().hasClass('css-6q0nyr-Svg') && !$(e.target).hasClass('css-ykwh22') && !$(e.target).hasClass('rc-time-picker-clear') && !$(e.target).hasClass('rc-time-picker-clear-icon')
            && ($(e.target)[0] !== $('body')[0]) && ($(e.target).parents('.MuiPaper-root').length !== 1) && ($(e.target).parents('.MuiPopover-root').length !== 1) && !$(e.target).attr("aria-hidden")) {


            window.timeControlModus = ""
            window.timeControlDelete = false
            setSettingsShow(false)
        }






        else {
            return
        }

    });



    // Update SVG Map

    function svg(id, area) {

        let interval

        axios.all([axios.get(link + '/api/Lot/ByAreaId?areaId=' + id), axios.get(link + '/api/NumericalDisplay/ByAreaId?areaId=' + id), axios.get(link + '/api/ZoneDisplay/ByAreaId?areaId=' + id), axios.get(link + '/api/TextDisplay/ByAreaId?areaId=' + id)
        ])
            .then(axios.spread((...responses) => {

                mapdata = []

                const response = responses[0];

                for (let i = 0; i < response.data.length; i++) {
                    lots[i] = response.data[i]

                    let data = response.data[i]
                    data.type = "Lot"
                    mapdata.push(data)
                }
                console.log(`Loaded ${response.data.length} Lots`);

                const response2 = responses[1];

                for (let i = 0; i < response2.data.length; i++) {
                    displays[i] = response2.data[i]

                    let data = response2.data[i]
                    data.type = "Display"
                    data.displayType = "NumericalDisplay"
                    mapdata.push(data)
                }
                console.log(`Loaded ${response2.data.length} NumDisplays`);


                const response3 = responses[2];

                for (let i = 0; i < response3.data.length; i++) {
                    displays[i] = response3.data[i]
                    let data = response3.data[i]
                    data.type = "Display"
                    data.displayType = "ZoneDisplay"
                    mapdata.push(data)
                }
                console.log(`Loaded ${response3.data.length} ZoneDisplays`);


                const response4 = responses[3];

                for (let i = 0; i < response4.data.length; i++) {
                    displays[i] = response4.data[i]
                    let data = response4.data[i]
                    data.type = "Display"
                    data.displayType = "TextDisplay"
                    mapdata.push(data)
                }
                console.log(`Loaded ${response4.data.length} TextDisplays`);


                interval = setInterval(updateSvgInitial, 1000);

            })

            )



        if (fsmodeon == true) {
            let svgbigger = setInterval(makeSvgBigger, 50);
            function makeSvgBigger() {
                if ($('.svg').find('svg').length != 0) {
                    $('.svg').addClass('svgFS')
                    clearInterval(svgbigger);
                }
            }
        }

        $(document).on('click', function (e) {

            if (carsselected == true && e.ctrlKey == false && !$(e.target).is('.react-contextmenu-item') && tabletselectmode == false
                && !$(e.target).is('.contextmenu-item') && !$(e.target).is('b') && !$(e.target).parent().is('b')) {


                for (let i = 0; i < selectedCarsAll.length; i++) {
                    let parkplace = document.getElementById(selectedCarsAll[i].name)

                    // get IDs for converted svg maps that take the zeros out of the IDs

                    if (parkplace == null && parkplace.slice(-1) == "0") {
                        parkplace = document.getElementById(parkplace.slice(0, -1))
                        if (parkplace == null && selectedcars[i].name.slice(-2) == "00") {
                            parkplace = document.getElementById(parkplace.slice(0, -2))
                        }
                        if (parkplace == null && selectedcars[i].name.slice(-3) == "000") {
                            parkplace = document.getElementById(parkplace.slice(0, -4))
                        }
                    }

                    $(parkplace).find('rect').eq(0).attr("stroke", "null").attr("stroke-opacity", "null").attr("stroke-width", "null")
                }

                selectedcars = []
                selectedCarsAll = []
                carsselected = false



            }

            if (!$(e.target).is('.react-contextmenu-item') && !$(e.target).is('.submenu-title') && !$(e.target).is('.contextmenu-item')
                && !$(e.target).is('b') && !$(e.target).parent().is('b')) {
                $(".contextmenu").hide()
            }


            else {
                return
            }

        });


        $('#svgmap').on('contextmenu', function (e) {

            if (carsselected == true) {

                let addToTopContext = e.pageY
                let addToLeftContext = e.pageX

                if (e.pageY > window.innerHeight - $('#contextmenu2').height()) {
                    addToTopContext = e.pageY - $('#contextmenu2').height() - 20
                }

                if (e.pageX + $('#contextmenu2').width() * 2 > window.innerWidth) {
                    addToLeftContext = window.innerWidth - $('#contextmenu2').width() * 2
                }


                //check if all cars have light intensity

                let counter = 0

                for (let i = 0; i < selectedcars.length; i++) {
                    if (selectedcars[i].hasOwnProperty('lightIntensity_Current')) {
                        counter++
                    }
                }


                if (counter == selectedcars.length) {
                    allCarsHaveLightIntensity = true
                }

                else {
                    allCarsHaveLightIntensity = false
                }

                //phone landscape modus 

                if (window.innerWidth < 813) {
                    if (window.innerHeight < window.innerWidth) {
                        addToLeftContext = '50%'
                        addToTopContext = '50%'
                        $('#contextmenu2').css('transform', 'translateX(-50%) translateY(-50%)')
                    }
                }

                $("#contextmenu2").css('top', addToTopContext).css('left', addToLeftContext).show()
                $('.react-contextmenu').show()
                    ;

                setHoverLotsContext(current => !current)
                return false;
            }

        });


        function updateSvgInitial() {


            if ($('.svg').find('svg').length != 0) {
                clearInterval(interval);

                let color1
                let color2

                console.debug(`Loading SVG with ${mapdata.length} Mapdata`);
                for (let i = 0; i < mapdata.length; i++) {

                    let parkplace
                    let display
                    let item

                    if (mapdata[i].displayType == "TextDisplay") {

                        let config

                        if (mapdata[i].mode == 0) {
                            if (mapdata[i].currentFreeValue > 0) {
                                config = 1
                            }
                            else {
                                config = 2
                            }
                        }
                        else {
                            config = mapdata[i].mode
                        }



                        axios.get(link + '/api/TextDisplay/Config?elementId=' + mapdata[i].id, {


                        }).then((response) => {

                            let data = response.data


                            for (let j = 0; j < data.length; j++) {

                                if (data[j].configType == config) {

                                    let orientation = data[j].orientation
                                    let text = data[j].text
                                    let displayMode = data[j].displayMode
                                    let colorB = data[j].colorB
                                    let colorG = data[j].colorG
                                    let colorR = data[j].colorR
                                    let fontSize = data[j].fontSize
                                    let fontName = data[j].fontName

                                    let item = document.getElementById(mapdata[i].name)

                                    $(item).attr('fill', "rgb(" + colorR + "," + colorG + "," + colorB + ")")

                                    if (displayMode == 0) {
                                        $(item).text(mapdata[i].currentFreeValue)
                                    }

                                    else {
                                        $(item).text(text).attr("font-size", fontSize).attr("style", "font-family:" + fontName)
                                    }

                                    let boxWidth = item.previousElementSibling.getBBox().width
                                    let itemWidth = item.getComputedTextLength().toFixed(1)

                                    if (itemWidth <= Math.ceil(boxWidth)) {

                                        if (orientation == 0) {
                                            $(item).attr('x', 1).attr('text-anchor', 'start')
                                        }

                                        else if (orientation == 1) {
                                            $(item).attr('x', (boxWidth - itemWidth) / 2).attr('text-anchor', 'start')
                                        }

                                        else {
                                            $(item).attr('x', boxWidth - itemWidth).attr('text-anchor', 'start')
                                        }


                                    }


                                    else {

                                        if (displayMode != 0) {

                                            $(item).empty();

                                            let tL = text.length
                                            let fS = 30
                                            let dY = 0.7
                                            let x = null

                                            if (tL > 5) {
                                                $(item).attr('x', 1).attr('text-anchor', 'start')
                                                x = 3
                                            }

                                            if (tL >= 4 && tL <= 6) {
                                                fS = 17; dY = 1
                                            }
                                            else if (tL > 6 && tL <= 14) {
                                                fS = 13; dY = 0.5
                                            }
                                            else if (tL > 14 && tL <= 16) {
                                                fS = 12; dY = 0.45
                                            }
                                            else if (tL > 16 && tL <= 18) {
                                                fS = 11; dY = 0.4
                                            }
                                            else if (tL > 18 && tL <= 33) {
                                                fS = 9; dY = 0.35
                                            }
                                            else if (tL > 33 && tL <= 50) {
                                                fS = 8; dY = 0.3
                                            }
                                            else if (tL > 50 && tL <= 75) {
                                                fS = 7; dY = 0.25
                                            }
                                            else if (tL > 75 && tL <= 100) {
                                                fS = 6; dY = 0.20
                                            }
                                            else if (tL > 100) {
                                                fS = 5; dY = 0.15
                                            }

                                            let arr = text.split(/(\s+)/)

                                            $(item).attr("font-size", fS)

                                            let str = ""
                                            let oldStr = ""
                                            let fullStr = ""
                                            let lastNode = false

                                            for (let i = 0; i < arr.length; i++) {

                                                str += arr[i]
                                                fullStr += arr[i]

                                                if (str.charAt(0) === " ") {
                                                    str = str.substring(1);
                                                }

                                                let node = document.createElementNS("http://www.w3.org/2000/svg", "tspan");

                                                node.setAttribute('y', 6)
                                                node.setAttribute('x', x)
                                                node.setAttribute('dy', dY + "em")

                                                if (fullStr.length > 170) {
                                                    str += "..."
                                                    oldStr = oldStr + "..."
                                                    lastNode = true
                                                }
                                                node.innerHTML = str;

                                                item.appendChild(node)

                                                let nodeWidth = node.getComputedTextLength().toFixed(1)

                                                if (arr.length == i + 1) {
                                                    lastNode = true
                                                }

                                                if (nodeWidth >= Math.ceil(boxWidth) || lastNode == true) {

                                                    if (nodeWidth - boxWidth >= 3 || lastNode == true) {

                                                        if (lastNode == true && nodeWidth - boxWidth >= 3) {
                                                            if (arr.length == 1) {
                                                                item.appendChild(node)
                                                                return
                                                            }
                                                            node.innerHTML = oldStr
                                                            item.removeChild(node)
                                                            item.appendChild(node)
                                                            dY = dY + 1
                                                            let nodeLast = document.createElementNS("http://www.w3.org/2000/svg", "tspan");
                                                            nodeLast.setAttribute('y', 6)
                                                            nodeLast.setAttribute('x', x)
                                                            nodeLast.setAttribute('dy', dY + "em")
                                                            nodeLast.innerHTML = arr[i]
                                                            item.appendChild(nodeLast)
                                                            return
                                                        }
                                                        else if (lastNode == true) {
                                                            node.innerHTML = str
                                                        }
                                                        else {
                                                            node.innerHTML = oldStr
                                                            str = arr[i]
                                                        }

                                                    }
                                                    else {
                                                        str = ""
                                                    }




                                                    item.removeChild(node)
                                                    item.appendChild(node)
                                                    dY = dY + 1

                                                }

                                                else {
                                                    item.removeChild(node)
                                                }

                                                oldStr = str

                                                if (fullStr.length > 170) {
                                                    return
                                                }
                                            }

                                        }

                                    }
                                }
                            }


                        })

                            .catch(error => {

                            })

                    }


                    //displays


                    if (mapdata[i].type == "Display") {

                        display = document.getElementById(mapdata[i].name)

                        //Zone

                        if (mapdata[i].displayType == "ZoneDisplay") {


                            if (mapdata[i].xSign == true) {
                                $(display).find('path').eq(7).attr('fill', 'red')
                                $(display).find('path').eq(8).attr('fill', 'red')
                                $(display).find('polygon').eq(6).attr('fill', 'red')
                                $(display).find('polygon').eq(7).attr('fill', 'red')
                            }

                            else {
                                $(display).find('path').eq(7).attr('fill', 'none')
                                $(display).find('path').eq(8).attr('fill', 'none')
                                $(display).find('polygon').eq(6).attr('fill', 'none')
                                $(display).find('polygon').eq(7).attr('fill', 'none')
                            }

                            if (mapdata[i].aheadArrow == true) {
                                $(display).find('path').eq(1).attr('fill', '#00FF00')
                                $(display).find('path').eq(5).attr('fill', '#00FF00')
                                $(display).find('polygon').eq(0).attr('fill', '#00FF00')
                                $(display).find('polygon').eq(4).attr('fill', '#00FF00')
                            }

                            else {
                                $(display).find('path').eq(1).attr('fill', 'none')
                                $(display).find('path').eq(5).attr('fill', 'none')
                                $(display).find('polygon').eq(0).attr('fill', 'none')
                                $(display).find('polygon').eq(4).attr('fill', 'none')
                            }

                            if (mapdata[i].leftArrow == true && mapdata[i].aheadArrow == true) {
                                $(display).find('path').eq(2).attr('fill', '#00FF00')
                                $(display).find('path').eq(4).attr('fill', '##00FF00')
                                $(display).find('polygon').eq(1).attr('fill', '#00FF00')
                                $(display).find('polygon').eq(3).attr('fill', '#00FF00')
                            }

                            else if (mapdata[i].leftArrow == true && mapdata[i].aheadArrow == false) {
                                $(display).find('path').eq(2).attr('fill', '#00FF00')
                                $(display).find('path').eq(4).attr('fill', '#00FF00')
                                $(display).find('path').eq(3).attr('fill', '#00FF00')
                                $(display).find('polygon').eq(1).attr('fill', '#00FF00')
                                $(display).find('polygon').eq(3).attr('fill', '#00FF00')
                                $(display).find('polygon').eq(2).attr('fill', '#00FF00')
                            }

                            if (mapdata[i].rightArrow == true && mapdata[i].aheadArrow == true) {
                                $(display).find('path').eq(3).attr('fill', '#00FF00')
                                $(display).find('path').eq(6).attr('fill', '#00FF00')
                                $(display).find('polygon').eq(2).attr('fill', '#00FF00')
                                $(display).find('polygon').eq(5).attr('fill', '#00FF00')
                            }


                            else if (mapdata[i].rightArrow == true && mapdata[i].aheadArrow == false) {
                                $(display).find('path').eq(3).attr('fill', '#00FF00')
                                $(display).find('path').eq(6).attr('fill', '#00FF00')
                                $(display).find('path').eq(2).attr('fill', '#00FF00')
                                $(display).find('polygon').eq(2).attr('fill', '#00FF00')
                                $(display).find('polygon').eq(5).attr('fill', '#00FF00')
                                $(display).find('polygon').eq(1).attr('fill', '#00FF00')
                            }


                            if (mapdata[i].leftArrow != true) {
                                $(display).find('path').eq(4).attr('fill', 'none')
                                $(display).find('polygon').eq(3).attr('fill', 'none')

                                if (mapdata[i].aheadArrow == true) {
                                    $(display).find('path').eq(2).attr('fill', 'none')
                                    $(display).find('polygon').eq(1).attr('fill', 'none')
                                }

                            }

                            if (mapdata[i].rightArrow != true) {
                                $(display).find('path').eq(6).attr('fill', 'none')
                                $(display).find('polygon').eq(5).attr('fill', 'none')

                                if (mapdata[i].aheadArrow == true) {
                                    $(display).find('path').eq(3).attr('fill', 'none')
                                    $(display).find('polygon').eq(2).attr('fill', 'none')
                                }
                            }

                            if (mapdata[i].leftArrow != true && mapdata[i].rightArrow != true && mapdata[i].aheadArrow != true) {
                                $(display).find('path').eq(2).attr('fill', 'none')
                                $(display).find('path').eq(3).attr('fill', 'none')
                                $(display).find('polygon').eq(1).attr('fill', 'none')
                                $(display).find('polygon').eq(2).attr('fill', 'none')
                            }



                        }


                        //for hover on numerical display (is a tspan in svg, need to select the parent)

                        if (mapdata[i].displayType == "NumericalDisplay") {

                            if (mapdata[i].mode == 1) {
                                $(display).attr('fill', 'none')
                            }

                            else if (mapdata[i].mode == 2) {
                                $(display).text("XXX").attr('fill', 'red')
                            }

                            else {
                                $(display).text((mapdata[i].displayValueNumeric)).attr('fill', '#00FF00')
                            }



                            if (document.getElementById(mapdata[i].name) != null) {
                                display = $(document.getElementById(mapdata[i].name)).parent().parent()
                            }
                        }

                    }

                    //parkplaces

                    else {

                        parkplace = document.getElementById(mapdata[i].name)


                        //get IDs for converted svg maps that take the zeros out of the IDs
                        if (parkplace == null && mapdata[i].name.slice(-1) == "0") {
                            parkplace = document.getElementById(mapdata[i].name.slice(0, -1))
                            if (parkplace == null && mapdata[i].name.slice(-2) == "00") {
                                parkplace = document.getElementById(mapdata[i].name.slice(0, -2))
                            }
                            if (parkplace == null && mapdata[i].name.slice(-3) == "000") {
                                parkplace = document.getElementById(mapdata[i].name.slice(0, -4))
                            }
                        }
                        if (parkplace == null) {
                            console.warn("Did not found SVG Lot for '%s'", mapdata[i].name);
                        }

                        if (area == mapdata[i].parentAreaName) {
                            $(parkplace).find('rect').eq(0).attr("stroke", "red").attr("stroke-opacity", "1.0").attr("stroke-width", "70")
                        }

                        else (
                            $(parkplace).find('rect').eq(0).attr("stroke", "null").attr("stroke-opacity", "null").attr("stroke-width", "null")
                        )

                        if (selectedcars.length != 0) {
                            for (let x = 0; x < selectedcars.length; x++) {
                                if (mapdata[i].id == selectedcars[x]) {
                                    $(parkplace).find('rect').eq(0).attr("stroke", "purple").attr("stroke-opacity", "1.0").attr("stroke-width", "70")
                                }
                            }
                        }


                        if (mapdata[i].occupied == true) {
                            color1 = occupColor1
                            color2 = occupColor2
                        }

                        else if (mapdata[i].occupied != true) {
                            color1 = freeColor1
                            color2 = freeColor2
                        }

                        if (mapdata[i].defect == true) {
                            color1 = defColor1
                            color2 = defColor2
                        }

                        if (mapdata[i].occupied == true && mapdata[i].reserved == true) {
                            color1 = occupResColor1
                            color2 = occupResColor2
                        }

                        if (mapdata[i].occupied == true && mapdata[i].exceeded == true) {
                            color1 = occupExcColor1
                            color2 = occupExcColor2
                        }

                        if (mapdata[i].occupied == false && mapdata[i].reserved == true) {
                            color1 = freeResColor1
                            color2 = freeResColor2
                        }

                        if (mapdata[i].reserved == true && mapdata[i].defect == true) {
                            color1 = defResColor1
                            color2 = defResColor2
                        }

                        if (mapdata[i].defect == null) {
                            color1 = unknownColor1
                            color2 = unknownColor2
                        }


                        if (color1 == null && color2 == null || color1 == "white" && color2 == "white") {
                            $(parkplace).find('path').css('fill-opacity', "0.0").css('stroke-opacity', "0.0")
                        }

                        else {
                            $(parkplace).find('path').css('fill-opacity', "1.0").css('stroke-opacity', "1.0")
                            $(parkplace).find('path').eq(0).css('fill', color1)
                            $(parkplace).find('path').eq(3).css('fill', color2)
                        }





                        if (mapdata[i].mode != 0) {

                            let view
                            if ($("tspan:contains(" + mapdata[i].name + ")").parent()[0]) {
                                view = ($("tspan:contains(" + mapdata[i].name + ")").parent()[0].getAttribute("font-size"))
                            }
                            else {
                                view = 27
                            }

                            let strokewidth = 2 * (view / 27)

                            if (mapdata[i].mode == 1) {

                                $("tspan").filter(function () {
                                    return $(this).text() === mapdata[i].name;
                                }).attr("fill", "green").attr("stroke", "green").attr("stroke", "green").attr("stroke-width", strokewidth)

                            }
                            else if (mapdata[i].mode == 2) (

                                $("tspan").filter(function () {
                                    return $(this).text() === mapdata[i].name;
                                }).attr("fill", "red").attr("stroke", "red").attr("stroke-width", strokewidth)

                            )
                        }

                        else (
                            $("tspan:contains(" + mapdata[i].name + ")").attr("fill", "").attr("stroke-width", "0")
                        )







                        if (mapdata[i].type == "Lot") {


                            try {
                                let r = 255
                                let g = 255
                                let b = 255

                                if (mapdata[i].hasOwnProperty('CurrentStateColorForFree') && mapdata[i].CurrentStateColorForFree != null) {
                                    r = mapdata[i].CurrentStateColorForFree.r
                                    g = mapdata[i].CurrentStateColorForFree.g
                                    b = mapdata[i].CurrentStateColorForFree.b
                                }

                                else if (mapdata[i].lotType != null) {
                                    r = mapdata[i].lotType.colorFree.r
                                    g = mapdata[i].lotType.colorFree.g
                                    b = mapdata[i].lotType.colorFree.b
                                }



                                $(parkplace).find('rect').eq(0).attr("fill", "rgb(" + r + "," + g + "," + b + ")").attr("fill-opacity", "0.3")
                            } catch (error) {
                                console.error('Fehler beim setzen der Hintergrundfarbe', error);
                            }



                        }


                    }



                    $(parkplace).on("click", function (e) {
                        if (e.ctrlKey || tabletselectmode == true) {
                            if (!selectedcars.includes(mapdata[i].id)) {
                                $(parkplace).find('rect').eq(0).attr("stroke", "purple").attr("stroke-opacity", "1.0").attr("stroke-width", "70")
                                carsselected = true
                                selectedcars.push(mapdata[i].id)
                                selectedCarsAll.push(mapdata[i])
                            }
                        }
                    });


                    //context menu, hover for parkplace or displays

                    if (mapdata[i].displayType == "ZoneDisplay") {
                        item = $(display)
                    }

                    else if (mapdata[i].displayType == "TextDisplay" || mapdata[i].displayType == "NumericalDisplay") {
                        item = $(display).parent()
                    }

                    else {
                        item = parkplace
                    }

                    $(item).on('contextmenu', function (e) {

                        if (carsselected != true) {
                            $('#hoverLots').hide();
                            contextData = mapdata[i]
                            contextOccupied = mapdata[i].occupied
                            contextName = mapdata[i].name
                            contextId = mapdata[i].id
                            contextMode = mapdata[i].mode
                            contextReserved = mapdata[i].reserved
                            contextType = mapdata[i].displayType
                            
                            contextLightIntensityDisplay = mapdata[i].lightIntensity_Current
                            contextLightIntensityLots = mapdata[i].lightIntensity_Current
                            contextHasCamera = mapdata[i].videoFeedAvailable


                            if (mapdata[i].parkingTimeRestrictionInMinutes != null) {
                                function TimeLimit(num) {
                                    contextTimeDD = Math.floor(num / 1440);
                                    contextTimeHH = Math.floor((num - (contextTimeDD * 1440)) / 60);
                                    contextTimeMM = Math.round(num % 60);
                                }

                                TimeLimit(mapdata[i].parkingTimeRestrictionInMinutes)
                            }



                            //lots that are not connected should have defect:null and not occupied!
                            if (mapdata[i].occupied != null) {
                                contextType = "Lot"
                            }

                            let addToTopContext = e.pageY
                            let addToLeftContext = e.pageX

                            if (e.pageY > window.innerHeight - $('#contextmenu').height()) {
                                addToTopContext = e.pageY - $('#contextmenu').height() - 20
                            }

                            if (e.pageX + $('#contextmenu').width() * 2 > window.innerWidth) {
                                addToLeftContext = window.innerWidth - $('#contextmenu').width() * 2
                            }



                            //phone landscape modus 

                            if (window.innerWidth < 813) {
                                if (window.innerHeight < window.innerWidth) {
                                    addToLeftContext = '50%'
                                    addToTopContext = '50%'
                                    $('#contextmenu').css('transform', 'translateX(-50%) translateY(-50%)')
                                }
                            }

                            $("#contextmenu").css('top', addToTopContext).css('left', addToLeftContext).show()
                            $('.react-contextmenu').show();

                            setHoverLotsContext(current => !current)
                            return false;

                        }
                    });


                    //only for phone

                    $(item).click(function (e) {

                        if (window.innerWidth < 813) {

                            if (tabletselectmode == true && supportsTouch == true) {
                                return
                            }

                            $("#hoverLots").css('top', '50%').css('left', '50%').css('transform', 'translateX(-50%) translateY(-50%)')


                            mapHoverName = mapdata[i].name
                            mapHoverLight = mapdata[i].lightIntensity_Current
                            mapHoverLightDefault = mapdata[i].lightIntensity_Default
                            mapHoverLicense = mapdata[i].licensePlate
                            mapHoverRemark = mapdata[i].remark



                            if (mapdata[i].parkingTimeRestrictionEnabled == true) {
                                mapHaveTimeLimit = true
                            }

                            else {
                                mapHaveTimeLimit = false
                            }

                            //zonedisplays

                            if (mapdata[i].displayType == "ZoneDisplay") {
                                zonedisplay = true
                            }

                            else {
                                zonedisplay = false
                            }

                            //Arrows

                            mapLeftArrow = null
                            mapAheadArrow = null
                            mapRightArrow = null
                            mapXSign = null

                            if (mapdata[i].leftArrow == false) {
                                mapLeftArrow = props.t('allgemein.aus')
                            }

                            else if (mapdata[i].leftArrow == true) {
                                mapLeftArrow = props.t('allgemein.an')
                            }

                            if (mapdata[i].aheadArrow == false) {
                                mapAheadArrow = props.t('allgemein.aus')
                            }

                            else if (mapdata[i].aheadArrow == true) {
                                mapAheadArrow = props.t('allgemein.an')
                            }

                            if (mapdata[i].rightArrow == false) {
                                mapRightArrow = props.t('allgemein.aus')
                            }

                            else if (mapdata[i].rightArrow == true) {
                                mapRightArrow = props.t('allgemein.an')
                            }

                            if (mapdata[i].xSign == false) {
                                mapXSign = props.t('allgemein.aus')
                            }

                            else if (mapdata[i].xSign == true) {
                                mapXSign = props.t('allgemein.an')
                            }

                            //Arrow Modes

                            mapLeftArrowMode = null
                            mapAheadArrowMode = null
                            mapRightArrowMode = null
                            mapXSignMode = null

                            if (mapdata[i].leftArrowMode == 0) {
                                mapLeftArrowMode = props.t('allgemein.automatik')
                            }

                            else {
                                mapLeftArrowMode = props.t('allgemein.manuell')
                            }

                            if (mapdata[i].aheadArrowMode == 0) {
                                mapAheadArrowMode = props.t('allgemein.automatik')
                            }

                            else {
                                mapAheadArrowMode = mapLeftArrowMode = props.t('allgemein.manuell')
                            }


                            if (mapdata[i].rightArrowMode == 0) {
                                mapRightArrowMode = props.t('allgemein.automatik')
                            }

                            else {
                                mapRightArrowMode = props.t('allgemein.manuell')
                            }

                            if (mapdata[i].xSignMode == 0) {
                                mapXSignMode = props.t('allgemein.automatik')
                            }

                            else {
                                mapXSignMode = props.t('allgemein.manuell')
                            }



                            //numeric displays
                            if (mapdata[i].displayType == "NumericalDisplay") {
                                mapDisplayValue = mapdata[i].displayValueNumeric
                            }
                            //text displays

                            else if (mapdata[i].displayType == "TextDisplay") {
                                mapDisplayValue = mapdata[i].currentFreeValue
                            }

                            else {
                                mapDisplayValue = null
                            }



                            if (mapdata[i].hasOwnProperty('Occupied')) {

                                mapHoverArea = mapdata[i].parentAreaName

                                if (mapdata[i].occupied == true) {
                                    mapHoverStatus = props.t('allgemein.besetzt')
                                }

                                else (
                                    mapHoverStatus = props.t('allgemein.frei')
                                )

                                if (mapdata[i].defect == null) {
                                    mapHoverStatus = props.t('allgemein.unbekannt')
                                }


                                if (mapdata[i].mode == 0) {
                                    mapHoverModus = props.t('lot.modes.Automatic')
                                }

                                else if (mapdata[i].mode == 1) {
                                    mapHoverModus = props.t('lot.modes.ManualFree')
                                }

                                else if (mapdata[i].mode == 2) {
                                    mapHoverModus = props.t('lot.modes.ManualOccupied')
                                }



                                //type

                                if (mapdata[i].lotType.name != null) {
                                    for (let x = 0; x < types.length; x++) {
                                        if (mapdata[i].lotType.name == types[x].name) {
                                            mapHoverType = types[x].name
                                        }
                                    }
                                }
                                else {
                                    mapHoverType = props.t('elementeAllgemein.typen.None')
                                }


                                if (mapHaveTimeLimit == true) {
                                    if (mapdata[i].parkingTimeRestrictionInMinutes > 0) {

                                        function TimeLimit(num) {
                                            let daysT = Math.floor(num / 1440);
                                            let hoursT = Math.floor((num - (daysT * 1440)) / 60);
                                            let minutesT = Math.round(num % 60);

                                            let dT = ('0' + daysT).slice(-2) + "d "
                                            if (daysT < 1) {
                                                dT = ""
                                            }
                                            if (daysT > 99) {
                                                dT = daysT + "d "
                                            }
                                            let hT = ('0' + hoursT).slice(-2) + "h "
                                            let mT = ('0' + minutesT).slice(-2) + "m"

                                            return (
                                                mapTimeLimit = dT + hT + mT
                                            )

                                        }

                                        TimeLimit(mapdata[i].parkingTimeRestrictionInMinutes)

                                    }

                                    else {
                                        mapTimeLimit = null
                                    }
                                }


                            }

                            else {
                                mapHoverArea = null
                                mapHoverStatus = null
                                mapHoverModus = null
                                mapHoverType = null
                                mapHoverLight = mapdata[i].lightIntensity_Current
                                mapHoverLightDefault = mapdata[i].lightIntensity_Default
                                mapHoverLicense = null
                                mapHoverRemark = null
                            }

                            let date = new Date(mapdata[i].lastTimeLotGotOccupied)
                            let date2 = new Date()

                            if (mapdata[i].lastTimeLotGotOccupied != null && mapdata[i].occupied == true) {
                                mapHaveTime = true
                            }
                            else (
                                mapHaveTime = false
                            )

                            mapHoverDate = ('0' + date.getDate()).slice(-2) + "." + ('0' + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear()
                            mapHoverTime = ('0' + date.getHours()).slice(-2) + ":" + ('0' + date.getMinutes()).slice(-2) + ":" + ('0' + date.getSeconds()).slice(-2)

                            let diffMS = Math.abs(date2 - date);

                            let days = Math.floor(diffMS / (24 * 60 * 60 * 1000));
                            let daysms = diffMS % (24 * 60 * 60 * 1000);
                            let hours = Math.floor((daysms) / (60 * 60 * 1000));
                            let hoursms = diffMS % (60 * 60 * 1000);
                            let minutes = Math.floor((hoursms) / (60 * 1000));
                            // let minutesms=diffMS % (60*1000);
                            // let sec = Math.floor((minutesms)/(1000));

                            let d = ('0' + days).slice(-2) + "d "
                            if (days < 1) {
                                d = ""
                            }
                            if (days > 99) {
                                d = days + "d "
                            }
                            let h = ('0' + hours).slice(-2) + "h "
                            let m = ('0' + minutes).slice(-2) + "m"

                            mapHoverTime2 = d + h + m

                            $('#hoverLots').show();
                            setHoverLots(true)




                        }

                        else {
                            return
                        }

                    })


                    let addToTop
                    let addToLeft

                    $(item).hover(function (e) {

                        //phone works with click, not hover like tablet and desktop

                        if (window.innerWidth < 813) {
                            return
                        }



                        if (tabletselectmode == true && supportsTouch == true) {
                            return
                        }


                        if (e.pageX + $('#hoverLots').width() + 50 > window.innerWidth) {
                            addToLeft = e.pageX - $('#hoverLots').width() - 20
                        }

                        else {
                            addToLeft = e.pageX + 20
                        }


                        if (e.pageY + $('#hoverLots').height() > window.innerHeight - 50) {
                            addToTop = e.pageY - $('#hoverLots').height() - 10
                        }

                        else {
                            addToTop = e.pageY + 10
                        }


                        $("#hoverLots").css('top', addToTop).css('left', addToLeft)



                        mapHoverName = mapdata[i].name
                        mapHoverLight = mapdata[i].lightIntensity_Current
                        mapHoverLightDefault = mapdata[i].lightIntensity_Default
                        mapHoverLicense = mapdata[i].licensePlate
                        mapHoverRemark = mapdata[i].remark


                        if (mapdata[i].parkingTimeRestrictionEnabled == true) {
                            mapHaveTimeLimit = true
                        }

                        else {
                            mapHaveTimeLimit = false
                        }

                        //zonedisplays

                        if (mapdata[i].displayType == "ZoneDisplay") {
                            zonedisplay = true
                        }

                        else {
                            zonedisplay = false
                        }

                        //Arrows

                        mapLeftArrow = null
                        mapAheadArrow = null
                        mapRightArrow = null
                        mapXSign = null


                        if (mapdata[i].leftArrow == false) {
                            mapLeftArrow = props.t('allgemein.aus')
                        }

                        else if (mapdata[i].leftArrow == true) {
                            mapLeftArrow = props.t('allgemein.an')
                        }

                        if (mapdata[i].aheadArrow == false) {
                            mapAheadArrow = props.t('allgemein.aus')
                        }

                        else if (mapdata[i].aheadArrow == true) {
                            mapAheadArrow = props.t('allgemein.an')
                        }

                        if (mapdata[i].rightArrow == false) {
                            mapRightArrow = props.t('allgemein.aus')
                        }

                        else if (mapdata[i].rightArrow == true) {
                            mapRightArrow = props.t('allgemein.an')
                        }

                        if (mapdata[i].xSign == false) {
                            mapXSign = props.t('allgemein.aus')
                        }

                        else if (mapdata[i].xSign == true) {
                            mapXSign = props.t('allgemein.an')
                        }

                        //Arrow Modes

                        mapLeftArrowMode = null
                        mapAheadArrowMode = null
                        mapRightArrowMode = null
                        mapXSignMode = null

                        if (mapdata[i].leftArrowMode == 0) {
                            mapLeftArrowMode = props.t('allgemein.automatik')
                        }

                        else {
                            mapLeftArrowMode = props.t('allgemein.manuell')
                        }

                        if (mapdata[i].aheadArrowMode == 0) {
                            mapAheadArrowMode = props.t('allgemein.automatik')
                        }

                        else {
                            mapAheadArrowMode = mapLeftArrowMode = props.t('allgemein.manuell')
                        }


                        if (mapdata[i].rightArrowMode == 0) {
                            mapRightArrowMode = props.t('allgemein.automatik')
                        }

                        else {
                            mapRightArrowMode = props.t('allgemein.manuell')
                        }

                        if (mapdata[i].xSignMode == 0) {
                            mapXSignMode = props.t('allgemein.automatik')
                        }

                        else {
                            mapXSignMode = props.t('allgemein.manuell')
                        }




                        if (mapdata[i].displayType == "NumericalDisplay") {
                            mapDisplayValue = mapdata[i].displayValueNumeric
                        }
                        //text displays

                        else if (mapdata[i].displayType == "TextDisplay") {
                            mapDisplayValue = mapdata[i].currentFreeValue
                        }

                        else {
                            mapDisplayValue = null
                        }


                        if (mapdata[i].hasOwnProperty('occupied')) {

                            mapHoverArea = mapdata[i].parentAreaName

                            if (mapdata[i].reserved == true) {
                                mapHoverReserved = props.t('allgemein.ja')
                            }

                            else (
                                mapHoverReserved = props.t('allgemein.nein')
                            )
                            if (mapdata[i].location != null) {
                                mapHoverGeoLat = mapdata[i].location.latitude
                                mapHoverGeoLon = mapdata[i].location.longitude
                            }
                            else {
                                mapHoverGeoLat = null
                                mapHoverGeoLon = null                                
                            }


                            if (mapdata[i].occupied == true) {
                                mapHoverStatus = props.t('allgemein.besetzt')
                            }

                            else (
                                mapHoverStatus = props.t('allgemein.frei')
                            )

                            if (mapdata[i].defect == null) {
                                mapHoverStatus = props.t('allgemein.unbekannt')
                            }


                            if (mapdata[i].mode == 0) {
                                mapHoverModus = props.t('lot.modes.Automatic')
                            }

                            else if (mapdata[i].mode == 1) {
                                mapHoverModus = props.t('lot.modes.ManualFree')
                            }

                            else if (mapdata[i].mode == 2) {
                                mapHoverModus = props.t('lot.modes.ManualOccupied')
                            }


                            //type

                            if (mapdata[i].lotType.name != null) {
                                for (let x = 0; x < types.length; x++) {
                                    if (mapdata[i].lotType.name == types[x].name) {
                                        mapHoverType = types[x].name
                                    }
                                }
                            }
                            else {
                                mapHoverType = props.t('elementeAllgemein.typen.None')
                            }




                            if (mapHaveTimeLimit == true) {
                                if (mapdata[i].parkingTimeRestrictionInMinutes > 0) {

                                    function TimeLimit(num) {
                                        let daysT = Math.floor(num / 1440);
                                        let hoursT = Math.floor((num - (daysT * 1440)) / 60);
                                        let minutesT = Math.round(num % 60);

                                        let dT = ('0' + daysT).slice(-2) + "d "
                                        if (daysT < 1) {
                                            dT = ""
                                        }
                                        if (daysT > 99) {
                                            dT = daysT + "d "
                                        }
                                        let hT = ('0' + hoursT).slice(-2) + "h "
                                        let mT = ('0' + minutesT).slice(-2) + "m"

                                        return (
                                            mapTimeLimit = dT + hT + mT
                                        )

                                    }

                                    TimeLimit(mapdata[i].parkingTimeRestrictionInMinutes)

                                }

                                else {
                                    mapTimeLimit = null
                                }
                            }





                        }


                        else {
                            mapHoverArea = null
                            mapHoverStatus = null
                            mapHoverModus = null
                            mapHoverType = null
                            mapHoverLight = mapdata[i].lightIntensity_Current
                            mapHoverLightDefault = mapdata[i].lightIntensity_Default
                            mapHoverLicense = null
                            mapHoverRemark = null
                            mapHoverReserved = null
                            mapHoverGeoLat = null
                            mapHoverGeoLon = null

                        }




                        let date = new Date(mapdata[i].lastTimeLotGotOccupied)
                        let date2 = new Date()

                        if (mapdata[i].lastTimeLotGotOccupied != null && mapdata[i].occupied == true) {
                            mapHaveTime = true
                        }
                        else (
                            mapHaveTime = false
                        )

                        mapHoverDate = ('0' + date.getDate()).slice(-2) + "." + ('0' + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear()
                        mapHoverTime = ('0' + date.getHours()).slice(-2) + ":" + ('0' + date.getMinutes()).slice(-2) + ":" + ('0' + date.getSeconds()).slice(-2)

                        let diffMS = Math.abs(date2 - date);

                        let days = Math.floor(diffMS / (24 * 60 * 60 * 1000));
                        let daysms = diffMS % (24 * 60 * 60 * 1000);
                        let hours = Math.floor((daysms) / (60 * 60 * 1000));
                        let hoursms = diffMS % (60 * 60 * 1000);
                        let minutes = Math.floor((hoursms) / (60 * 1000));
                        // let minutesms=diffMS % (60*1000);
                        // let sec = Math.floor((minutesms)/(1000));

                        let d = ('0' + days).slice(-2) + "d "
                        if (days < 1) {
                            d = ""
                        }
                        if (days > 99) {
                            d = days + "d "
                        }
                        let h = ('0' + hours).slice(-2) + "h "
                        let m = ('0' + minutes).slice(-2) + "m"

                        mapHoverTime2 = d + h + m

                        $('#hoverLots').show();
                        setHoverLots(true)

                    }
                        , //hide hover info container
                        function (e) {

                            $('#hoverLots').hide();
                            setHoverLots(false)

                        });

                    $(item).mousemove(function (e) {

                        //smartphone

                        if (window.innerWidth < 813) {
                            return
                        }

                        //others

                        if (e.pageX + $('#hoverLots').width() + 50 > window.innerWidth) {
                            addToLeft = e.pageX - $('#hoverLots').width() - 20
                        }

                        else {
                            addToLeft = e.pageX + 20
                        }


                        if (e.pageY + $('#hoverLots').height() > window.innerHeight - 50) {
                            addToTop = e.pageY - $('#hoverLots').height() - 10
                        }

                        else {
                            addToTop = e.pageY + 10
                        }

                        $("#hoverLots").css('top', addToTop).css('left', addToLeft)

                    })

                }
                console.debug(`Loaded SVG with ${mapdata.length} Mapdata`);



            }
            else {
                console.error("Did not found SVG DOM Element");
            }
        }

    }

    // Hover over lots
    function showLots() {

        function parktime() {
            if (mapHaveTime == true) {
                return (
                    <div className="timetable">
                        <tr >
                            <td className="tds" >{props.t('lot.parkBeginn')}</td>
                            <td className="tds2" >{mapHoverDate}<br></br>{mapHoverTime}</td>
                        </tr>
                        <tr >
                            <td className="tds" >{props.t('lot.parkDauer')}</td>
                            <td className="tds2" >{mapHoverTime2}</td>
                        </tr>
                    </div>
                )
            }
        }

        function light() {
            if (mapHoverLight != null) {
                return (
                    <div>
                        <tr>
                            <td className="tds">{props.t('allgemein.helligkeit')}</td>
                            <td className="tds2">{props.t('allgemein.aktuell')}: {mapHoverLight}%<br></br>{props.t('allgemein.standard')}: {mapHoverLightDefault}</td>
                        </tr>
                    </div>
                )
            }
        }

        function statusmodus() {
            if (mapHoverStatus != null) {
                return (
                    <div>
                        <tr>
                            <td className="tds">{props.t('allgemein.zustand')}</td>
                            <td className="tds2">{mapHoverStatus}<br></br> ({mapHoverModus})</td>
                        </tr>
                    </div>
                )
            }
        }

        function license() {
            if (mapHoverLicense != null && mapHoverLicense != "") {
                return (
                    <div>
                        <tr>
                            <td className="tds">{props.t('allgemein.kennzeichen')}</td>
                            <td className="tds2">{mapHoverLicense}</td>
                        </tr>
                    </div>
                )
            }
        }

        function reserved() {
            if (mapHoverReserved != null) {
                return (
                    <div>
                        <tr>
                            <td className="tds">{props.t('lot.reserviert')}</td>
                            <td className="tds2">{mapHoverReserved}</td>
                        </tr>
                    </div>
                )
            }
        }

        function type() {
            if (mapHoverType != null) {
                return (
                    <div>
                        <tr>
                            <td className="tds">{props.t('elementeAllgemein.typ')}</td>
                            <td className="tds2">{mapHoverType}</td>
                        </tr>
                    </div>
                )
            }
        }

        function area() {
            if (mapHoverArea != null) {
                return (
                    <div>
                        <tr>
                            <td className="tds">{props.t('elementeAllgemein.area')}</td>
                            <td className="tds2">{mapHoverArea}</td>
                        </tr>
                    </div>
                )
            }
        }

        function time() {
            if (mapHaveTimeLimit == true) {
                if (mapTimeLimit != null) {
                    return (
                        <div>
                            <tr>
                                <td className="tds breakwords">{props.t('elementeAllgemein.zeitueberwachung')}</td>
                                <td className="tds2">{mapTimeLimit}</td>
                            </tr>
                        </div>
                    )
                }
                else {
                    return (
                        <div>
                            <tr>
                                <td className="tds breakwords">{props.t('elementeAllgemein.zeitueberwachung')}</td>
                                <td className="tds2">{props.t('allgemein.aus')}</td>
                            </tr>
                        </div>)
                }
            }
        }

        function displayvalue() {
            if (mapDisplayValue != null) {
                return (
                    <div>
                        <tr>
                            <td className="tds">{props.t('numDisplay.aktuellerWert')}</td>
                            <td className="tds2">{mapDisplayValue}</td>
                        </tr>
                    </div>
                )
            }
        }

        function geocoordinates() {
            if ((mapHoverGeoLat != null) && (mapHoverGeoLon != null)) {
                return (
                    <div>
                        <tr>
                            <td className="tds">{props.t('elementeAllgemein.geoCoordinates')}</td>
                            <td className="tds2">Lat.: {mapHoverGeoLat}<br/>Lon.: {mapHoverGeoLon}</td>
                        </tr>
                    </div>
                )
            }
        }
        
        function zone() {

            if (zonedisplay == true) {
                return (
                    <div>
                        <tr>
                            <td className="tds" >{props.t('zoneDisplay.leftArrow')}</td>
                            <td className="tds2">{mapLeftArrowMode} &nbsp; {mapLeftArrow}</td>
                        </tr>
                        <tr>
                            <td className="tds" >{props.t('zoneDisplay.aheadArrow')}</td>
                            <td className="tds2">{mapAheadArrowMode} &nbsp; {mapAheadArrow}</td>
                        </tr>
                        <tr>
                            <td className="tds" >{props.t('zoneDisplay.rightArrow')}</td>
                            <td className="tds2">{mapRightArrowMode} &nbsp; {mapRightArrow}</td>
                        </tr>
                        <tr>
                            <td className="tds" >{props.t('zoneDisplay.symbol')}</td>
                            <td className="tds2">{mapXSignMode} &nbsp; {mapXSign}</td>
                        </tr>
                    </div>
                )
            }
        }

        function remark() {
            if (mapHoverRemark != null) {
                return (
                    <div>
                        <tr>
                            <td className="tds">{props.t('allgemein.bemerkung')}</td>
                            <td className="tds2">{mapHoverRemark}</td>
                        </tr>
                    </div>
                )
            }
        }

        return (
            <div id="hoverLots" ref={lotsContainer}>
                <div className="tablehovername">{mapHoverName}</div>
                <div className="svgtablewrapper">
                    {displayvalue()}
                    {zone()}
                    {statusmodus()}
                    {reserved()}
                    {license()}
                    {type()}
                    {area()}
                    {light()}
                    {parktime()}
                    {time()}
                    {remark()}
                    {geocoordinates()}

                </div>
            </div>
        )
    }

    function showTimeControl() {
        return (
            <div id="hoverTimeControl">

            </div>
        )
    }

    // Collect context function
    function collect(props) {
        treeContextData = props.children.props.data
        treeContextName = props.children.props.name
        treeContextId = props.children.props.id
        setHoverTreeContext(current => !current)
        $('.react-contextmenu').show()
    }

    // Render area tree
    const treePark = (site) => {

        let children = []
        let tree = []

        function mouseEnter() {
            setHoverTreeContext(current => !current)
        }

        for (let i = 0; i < mainparkinglots.length; i++) {

            let correctSite = false

            for (let j = 0; j < site.carParks.length; j++) {
                if (site.carParks[j].id == mainparkinglots[i].id) {
                    correctSite = true
                }
            }

            if (correctSite == true) {

                let dropdown = false
                let dropdownicon
                let parkImg = parkimg2

                for (let j = 0; j < subparkinglots.length; j++) {
                    if (mainparkinglots[i].name == subparkinglots[j].parentElement) {
                        dropdown = true

                        if (showLevelMain[i] == true) {
                            dropdownicon = dd2
                        }
                        else {
                            dropdownicon = dd1
                        }
                    }
                }

                children.push(
                    <div className="park2" onClick={() => setShowLevelMain({ ...showLevelMain, [i]: (!showLevelMain[i]) })}>
                        {dropdown == true && (
                            <img class="dropdown-icon" src={dropdownicon} />
                        )}
                        <ContextMenuTrigger id="treeContext" collect={collect}>
                            <div data={mainparkinglots[i]} id={mainparkinglots[i].id} key={"main" + i} className="flextree " name={mainparkinglots[i].name} onMouseOver={mouseEnter}>
                                <img class="parkimg2" src={parkImg} alt="park" />
                                <div style={{ marginLeft: '5px' }}>{mainparkinglots[i].name}</div>
                            </div>
                        </ContextMenuTrigger>
                    </div>
                )

                for (let j = 0; j < subparkinglots.length; j++) {

                    let dropdown = false
                    let dropdownicon
                    let parkImg = parkimg3

                    for (let x = 0; x < areaparkinglots.length; x++) {
                        if (subparkinglots[j].name == areaparkinglots[x].parentElement) {
                            dropdown = true

                            if (showLevelSub[j] == true) {
                                dropdownicon = dd2
                            }

                            else {
                                dropdownicon = dd1
                            }
                        }
                    }

                    if (mainparkinglots[i].name == subparkinglots[j].parentElement) {

                        function sub() {

                            currentmap = null
                            oldmap = null

                            if (subparkinglots[j].svgFileName) {
                                currentmap = <ReactSVG wrapper="span" className="svg" src={'./svgs/' + `${subparkinglots[j].svgFileName}.svg`} />
                                oldmap = <ReactSVG wrapper="span" className="svg" src={'./svgs/' + `${subparkinglots[j].svgFileName}.svg`} />
                            }

                            if (currentmap != null) {
                                setResetZoom(true)
                                svgshow = null
                                svg(subparkinglots[j].id)
                                setCurrentAreaId((subparkinglots[j].id).toString())
                            }

                            else {
                                svgshow = svgbackground
                            }

                            currentarea = null

                            parkName = site.name

                            if (oneParkingLot) {
                                activePark = mainparkinglots[i].name
                            }

                            else {
                                activePark = "/" + mainparkinglots[i].name
                            }


                            activePark2 = "/" + subparkinglots[j].name

                            setMap({ ...showMap, [j]: (!showMap[j]) })
                            setChangeMap(true)

                        }

                        children.push(
                            showLevelMain[i] == true && (

                                <div className="park3">

                                    {dropdown == true && (
                                        <img class="dropdown-icon" src={dropdownicon} onClick={() => setShowLevelSub({ ...showLevelSub, [j]: (!showLevelSub[j]) })} />
                                    )}

                                    <ContextMenuTrigger id="treeContext" collect={collect} >
                                        <div data={subparkinglots[j]} id={subparkinglots[j].id} key={"sub" + j} className="flextree" name={subparkinglots[j].name} onClick={sub} onMouseOver={mouseEnter}>
                                            <img class="parkimg2" src={parkImg} alt="park2" />
                                            <div style={{ marginLeft: '5px' }}>{subparkinglots[j].name}</div>
                                        </div>
                                    </ContextMenuTrigger>
                                </div>)
                        )


                        for (let x = 0; x < areaparkinglots.length; x++) {
                            if (subparkinglots[j].name == areaparkinglots[x].parentElement) {


                                function area() {

                                    currentmap = null

                                    if (subparkinglots[j].svgFileName) {
                                        currentmap = <ReactSVG wrapper="span" className="svg" src={'./svgs/' + `${subparkinglots[j].svgFileName}.svg`} />
                                    }

                                    if (currentmap != null) {
                                        if (oldmap != currentmap) {
                                            setResetZoom(true)
                                            oldmap = currentmap
                                        }

                                        svgshow = null
                                        svg(subparkinglots[j].id, areaparkinglots[x].name)
                                        currentarea = areaparkinglots[x].name
                                        setCurrentAreaId((subparkinglots[j].id).toString())
                                    }
                                    else {
                                        svgshow = svgbackground
                                    }

                                    parkName = site.name

                                    if (oneParkingLot) {
                                        activePark = mainparkinglots[i].name
                                    }

                                    else {
                                        activePark = "/" + mainparkinglots[i].name
                                    }

                                    activePark2 = "/" + subparkinglots[j].name + " - " + areaparkinglots[x].name
                                    setMap({ ...showMap, [j]: (!showMap[j]) })

                                    setChangeMap(true)
                                }


                                children.push(
                                    showLevelMain[i] == true && (
                                        showLevelSub[j] == true && (
                                            <ContextMenuTrigger id="treeContext" collect={collect} >
                                                <div data={areaparkinglots[x]} id={areaparkinglots[x].id} key={"area" + x} name={areaparkinglots[x].name} className="park4" onClick={area} onMouseOver={mouseEnter}>
                                                    <img class="parkimg2" src={parkimg4} alt="park3" />
                                                    <div style={{ marginLeft: '5px' }}>{areaparkinglots[x].name}</div>
                                                </div>
                                            </ContextMenuTrigger>
                                        )
                                    )
                                )

                            }
                        }


                    }
                }
            }
        }

        tree.push(<div>{children}</div>)
        return (tree)
    }

    function tree() {

        function main(site) {

            if (mainHasSvg) {
                if (mainMap != null) {
                    currentmap = mainMap
                    setResetZoom(true)
                    svgshow = null
                    svg()
                }

                else {
                    svgshow = svgbackground
                }

                parkName = site.name
                currentarea = null
                activePark = null
                activePark2 = null

                setChangeMap(true)
            }

            else {
                return
            }

        }

        if (pageLoaded) {

            if (oneParkingLot) {
                return (
                    <div id="parktreeall" ref={treeContainer}>
                        <SimpleBar className="simplebar-parktree">
                            <div style={{ marginLeft: '-5px' }}>
                                {treePark()}
                            </div>
                        </SimpleBar>
                    </div>
                )
            }

            else {
                return (
                    <div id="parktreeall" ref={treeContainer}>
                        <SimpleBar className="simplebar-parktree">
                            {sites.map((item, idx) => {

                                let dropdownicon
                                let parkImg = parkimg

                                if (showLevelFirst[idx] == true) {
                                    dropdownicon = dd2
                                }
                                else {
                                    dropdownicon = dd1
                                }

                                return (
                                    <>
                                        <div id="parkname" style={{ paddingTop: '10px' }}>
                                            <img class="dropdown-icon" src={dropdownicon} onClick={() => setShowLevelFirst({ ...showLevelFirst, [idx]: (!showLevelFirst[idx]) })} />
                                            <div onClick={main(item)} className="flex-iac">
                                                <img className="parkimg1" src={parkImg} alt="park" />
                                                <div style={{ marginLeft: '5px' }}>{item.name}</div>
                                            </div>
                                        </div>
                                        {showLevelFirst[idx] == true && (
                                            <div>
                                                {treePark(item)}
                                            </div>
                                        )}
                                    </>

                                )
                            })}


                        </SimpleBar>
                    </div>
                )

            }

        }
    }




    // Render table of areas on the right side
    const parkAreas = () => {

        let children = []
        let parkAreas = []

        for (let i = 0; i < mainparkinglots.length; i++) {

            let dropdown = false
            let dropdownicon

            for (let j = 0; j < subparkinglots.length; j++) {
                if (mainparkinglots[i].name == subparkinglots[j].parentElement) {
                    dropdown = true

                    if (showAreaMain[i] == true) {
                        dropdownicon = dd2
                    }
                    else {
                        dropdownicon = dd1
                    }
                }
            }


            let percentageStyle = {
                background: 'linear-gradient(to right, lightcoral ' + Math.round(mainparkinglots[i].areaValues != null ? mainparkinglots[i].areaValues.displayValueForOccupiedPercent : 0) + '%,transparent 0%'
            };



            function mouseMove() {

                if (supportsTouch != true) {
                    showInfo(mainparkinglots[i].id)
                    $('#showinfo').show()
                    setHover(current => !current)
                }

                else {
                    $('#' + mainparkinglots[i].id + "A").on('contextmenu', function (event) {
                        showInfo(mainparkinglots[i].id)
                        $('#showinfo').show()
                        setHover(current => !current)
                        return false;
                    });
                }
            }


            function mouseLeave() {
                $('#showinfo').hide()
                setHover(false)
            }


            children.push(
                <tr id={mainparkinglots[i].id + "A"} className="first" onMouseLeave={mouseLeave} onMouseMove={mouseMove}>
                    <td className="arearow1" data-column="Area" onClick={() => setShowAreaMain({ ...showAreaMain, [i]: (!showAreaMain[i]) })}>
                        <div className="flexarea">
                            {dropdown == true && (
                                <img class="dropdown-icon" src={dropdownicon} />
                            )}
                            <div className="leftarea">{mainparkinglots[i].name}</div>
                        </div>
                    </td>
                    <td className="td1" data-column="Proj">{mainparkinglots[i].areaValues != null ? mainparkinglots[i].areaValues.constructed : 0}</td>
                    <td className="td1" data-column="Frei">{mainparkinglots[i].areaValues != null ? mainparkinglots[i].areaValues.displayValueForFree : 0}</td>
                    <td className="td1" data-column="Bel">{mainparkinglots[i].areaValues != null ? mainparkinglots[i].areaValues.displayValueForOccupied : 0}</td>
                    <td className="td1" style={percentageStyle} data-column="Bel. %">{Math.round(mainparkinglots[i].areaValues != null ? mainparkinglots[i].areaValues.displayValueForOccupiedPercent : 0)}%</td>
                </tr>
            )


            for (let j = 0; j < subparkinglots.length; j++) {

                let dropdown = false
                let dropdownicon

                for (let x = 0; x < areaparkinglots.length; x++) {
                    if (subparkinglots[j].name == areaparkinglots[x].parentElement) {
                        dropdown = true

                        if (showAreaSub[j] == true) {
                            dropdownicon = dd2
                        }

                        else {
                            dropdownicon = dd1
                        }
                    }
                }



                let percentageStyle = {
                    background: 'linear-gradient(to right, lightcoral ' + Math.round(subparkinglots[j].areaValues != null ? subparkinglots[j].areaValues.displayValueForOccupiedPercent : 0) + '%,transparent 0%'
                };



                if (mainparkinglots[i].name == subparkinglots[j].parentElement) {


                    function mouseMove() {
                        if (supportsTouch != true) {
                            showInfo(subparkinglots[j].id)
                            $('#showinfo').show()
                            setHover(current => !current)
                        }

                        else {
                            $('#' + subparkinglots[j].id + "A").on('contextmenu', function (event) {
                                showInfo(subparkinglots[j].id)
                                $('#showinfo').show()
                                setHover(current => !current)
                                return false;
                            });
                        }
                    }


                    function mouseLeave() {
                        $('#showinfo').hide()
                        setHover(false)
                    }


                    children.push(
                        showAreaMain[i] == true && (
                            <tr id={subparkinglots[j].id + "A"} className="second" onMouseLeave={mouseLeave} onMouseMove={mouseMove}>
                                <td className="area arearow1sub" data-column="Area" onClick={() => setShowAreaSub({ ...showAreaSub, [j]: (!showAreaSub[j]) })}>
                                    <div className="flexarea">
                                        {dropdown == true && (
                                            <img class="dropdown-icon" src={dropdownicon} />
                                        )}
                                        <div className="leftarea">{subparkinglots[j].name}</div>
                                    </div>
                                </td>
                                <td className="td1" data-column="Proj">{subparkinglots[j].areaValues != null ? subparkinglots[j].areaValues.constructed : 0}</td>
                                <td className="td1" data-column="Frei">{subparkinglots[j].areaValues != null ? subparkinglots[j].areaValues.displayValueForFree : 0}</td>
                                <td className="td1" data-column="Bel">{subparkinglots[j].areaValues != null ? subparkinglots[j].areaValues.displayValueForOccupied : 0}</td>
                                <td className="td1" style={percentageStyle} data-column="Bel. %">{Math.round(subparkinglots[j].areaValues != null ? subparkinglots[j].areaValues.displayValueForOccupiedPercent : 0)}%</td>
                            </tr>
                        )
                    )



                    for (let x = 0; x < areaparkinglots.length; x++) {

                        let percentageStyle = {
                            background: 'linear-gradient(to right, lightcoral ' + Math.round(areaparkinglots[x].areaValues != null ? areaparkinglots[x].areaValues.displayValueForOccupiedPercent : 0) + '%,transparent 0%'
                        };


                        if (subparkinglots[j].name == areaparkinglots[x].parentElement) {


                            function mouseMove() {
                                if (supportsTouch != true) {
                                    showInfo(areaparkinglots[x].id)
                                    $('#showinfo').show()
                                    setHover(current => !current)
                                }

                                else {
                                    $('#' + areaparkinglots[x].id + "A").on('contextmenu', function (event) {
                                        showInfo(areaparkinglots[x].id)
                                        $('#showinfo').show()
                                        setHover(current => !current)
                                        return false;
                                    });
                                }
                            }


                            function mouseLeave() {
                                $('#showinfo').hide()
                                setHover(false)
                            }


                            children.push(
                                showAreaMain[i] == true && (
                                    showAreaSub[j] == true && (
                                        <tr id={areaparkinglots[x].id + "A"} className="third" onMouseLeave={mouseLeave} onMouseMove={mouseMove}>
                                            <td className="thirdarea td1" data-column="Area">{areaparkinglots[x].name}</td>
                                            <td className="td1" data-column="Proj">{areaparkinglots[x].areaValues != null ? areaparkinglots[x].areaValues.constructed : 0}</td>
                                            <td className="td1" data-column="Frei">{areaparkinglots[x].areaValues != null ? areaparkinglots[x].areaValues.displayValueForFree : 0}</td>
                                            <td className="td1" data-column="Bel">{areaparkinglots[x].areaValues != null ? areaparkinglots[x].areaValues.displayValueForOccupied : 0}</td>
                                            <td className="td1" style={percentageStyle} data-column="Bel. %">{Math.round(areaparkinglots[x].areaValues != null ? areaparkinglots[x].areaValues.displayValueForOccupiedPercent : 0)}%</td>
                                        </tr>
                                    )
                                )
                            )





                        }


                    }



                }


            }
        }



        parkAreas.push(
            <SimpleBar className="simplebar-areas">
                <tbody className="tbody1">{children}</tbody>
            </SimpleBar>
        )
        return (parkAreas)
    }

    function areasTable() {
        if (pageLoaded) {
            return (
                <table className="table1" ref={areaContainer}>
                    <thead className="thead1">
                        <tr className="tr1">
                            <th className="areahead1">{props.t('elementeAllgemein.area')}</th>
                            <th className="th1">{props.t('elementeAllgemein.areaWerte.Constructed_kurz')}</th>
                            <th className="th1">{props.t('elementeAllgemein.areaWerte.Free_kurz')}</th>
                            <th className="th1">{props.t('elementeAllgemein.areaWerte.Occupied_kurz')}</th>
                            <th className="th1">{props.t('elementeAllgemein.areaWerte.Occupied_kurz')}%</th>
                        </tr>
                    </thead>
                    {parkAreas()}
                </table>
            )
        }
    }


    // Context menu normal
    function contextSVG() {

        function getData(id) {
            for (let i = 0; i < mapdata.length; i++) {
                if (id == mapdata[i].id) {
                    return mapdata[i]
                }
            }

        }

        //way to bypass the problem with submenu, can't add onMouseOver on it to update the state 

        function mouseEnter(e) {
            //   let item=$(e.target).text()
            //   if (item==props.t('allgemein.helligkeit') || item==props.t('elementeAllgemein.kundengruppe') || item==props.t('elementeAllgemein.zeitueberwachung')) {
            //     setHoverContext(current => !current)
            //   }
            //   else {
            //     return
            //   }

        }

        function addLight() {
            if (contextLightIntensityLots != null) {
                return (
                    <SubMenu title={<div className="submenu-title"> {props.t('allgemein.helligkeit')} <i class="fas fa-chevron-right submenu-icon"></i> </div>} className="submenu">
                        <MenuItem disabled>
                            <div className="inputwrapper">
                                <NumericInput id="Light-Lot" className="react-contextmenu-numeric react-contextmenu-item" defaultValue={contextLightIntensityLots} mobile={"auto"} min={0} max={100} />
                                <div class="inputtext contextmenu-item">%</div>
                            </div>
                        </MenuItem>
                        <MenuItem divider />
                        <MenuItem className="react-contextmenu-item-send" onClick={() => handleUpdateLightIntensity({ contextId, contextName, value: ($('#Light-Lot').val()), type: 'Lot' })}>
                            {props.t('allgemein.senden')}
                        </MenuItem>
                    </SubMenu>
                )
            }
        }

        function contextLotsDisplays() {

            //zonedisplay
            if (contextType == "ZoneDisplay") {
                return (
                    <ZoneDisplay
                        contextId={contextId}
                        contextName={contextName}
                        userCanChange={userCanChange}
                        contextLightIntensityDisplay={contextLightIntensityDisplay}
                        showSettings={showSettings}
                        getData={getData}
                    />
                )
            }
            //numerical display
            else if (contextType == "NumericalDisplay") {
                return (
                    <NumericalDisplay 
                        contextId={contextId}
                        contextName={contextName}
                        userCanChange={userCanChange}
                        contextLightIntensityDisplay={contextLightIntensityDisplay}
                        showSettings={showSettings}
                        getData={getData}
                        updateLightIntensity={updateLightIntensity}
                    />
                )
            }

            //text display
            else if (contextType == "TextDisplay") {
                return (
                    <TextDisplay
                    contextId={contextId}
                    contextName={contextName}
                    userCanChange={userCanChange}
                    contextLightIntensityDisplay={contextLightIntensityDisplay}
                    showSettings={showSettings}
                    getData={getData}
                    />
                )
            }

            //lots 
            else {
                return (
                 <LotDisplay 
                    contextId={contextId}
                    contextName={contextName}
                    userCanChan={userCanChange}
                    addLight={addLight}
                    showSettings={showSettings}
                    getData={getData}
                    contextHasCamera={contextHasCamera}/>
                )
            }
        }

        return (
            contextLotsDisplays()
        )
    }

    // Calendar pop up
    function popUp() {

        const treeLots = () => {

            let children = []
            let tree = []
            let chosenType = selectedCarsAll

            for (let i = 0; i < chosenType.length; i++) {
                children.push(
                    <div id={chosenType[i].Id} name={chosenType[i].Name} key={"modus" + chosenType[i].Id} className="park-container">
                        <div className="flex-iac" style={{ height: '25px', paddingLeft: '15px' }}>
                            <div >{chosenType[i].Name}</div>
                        </div>
                    </div>
                )
            }

            tree.push(
                <div>
                    {children}
                </div>)
            return (tree)
        }


        function closeCalender() {
            setPopUpShow(false)
            selectedCarsAll = []
        }

        return (
            <div id="popUp">
                <SimpleBar style={{ height: '100%' }}>
                    <div style={{ width: '90%', margin: 'auto' }}>
                        <button class="tui-full-calendar-button tui-full-calendar-popup-close" onClick={closeCalender}><span class="tui-full-calendar-icon tui-full-calendar-ic-close"></span></button>
                        <div style={{ marginTop: '50px' }}>
                            <div className="title-calendar-container flex-sb">
                                <span class="tui-full-calendar-icon tui-full-calendar-ic-title" style={{ marginLeft: '5px' }}></span>
                                <input class="title-calendar" placeholder="Title" />
                            </div>
                            <div className="calendar-dates">
                                <DateTimePicker className="title-calendar-datetime" value={new Date()} min={new Date()} time={true} />
                                <DateTimePicker className="title-calendar-datetime" value={new Date()} min={new Date()} time={true} style={{ marginTop: '15px' }} />
                            </div>
                        </div>
                        <div style={{ width: '100%', marginTop: '15px' }}>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
                                    <div className="schedule-selection-part">
                                        <div className="content-group-name">Lots</div>
                                        <div className="list-elements" style={{ height: '200px' }}>
                                            <SimpleBar style={{ height: '100%' }}>
                                                {treeLots()}
                                            </SimpleBar>
                                        </div>
                                    </div>

                                    <div className="schedule-selection-part">
                                        <div className="content-group-name">{props.t('allgemein.modus')}</div>
                                        <div id="optionModusRadio">
                                            <label className="flex-iac">
                                                <input type="radio" name="radio-display" style={{ marginRight: '5px' }} /> <div>{props.t('lot.modes.Automatic')}</div>
                                            </label>
                                            <label className="flex-iac">
                                                <input type="radio" name="radio-display" style={{ marginRight: '5px' }} /> <div>{props.t('lot.modes.ManualFree')}</div>
                                            </label>
                                            <label className="flex-iac">
                                                <input type="radio" name="radio-display" style={{ marginRight: '5px' }} /> <div>{props.t('lot.modes.ManualOccupied')}</div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button class="button" style={{ marginTop: '15px', marginBottom: '15px' }} onClick={closeCalender}>{props.t('allgemein.speichern')}</button>
                    </div>
                </SimpleBar>
            </div>
        )
    }

    // Context menu multiple
    function contextSVG2() {

        // function showCalendar() {
        //   selectedcars=[]
        //   carsselected=false
        //   updateSvg()
        //   $(".contextmenu").hide()
        //   setPopUpShow(true)
        // }

        function addLightSel() {
            if (allCarsHaveLightIntensity != null) {
                return (
                    <SubMenu title={<div className="submenu-title"> {props.t('allgemein.helligkeit')} <i class="fas fa-chevron-right submenu-icon"></i> </div>} className="submenu">
                        <MenuItem disabled>
                            <div className="inputwrapper">
                                <NumericInput id="Light-SelLots" className="react-contextmenu-numeric react-contextmenu-item" value={0} mobile={"auto"} min={0} max={100} />
                                <div class="inputtext contextmenu-item">%</div>
                            </div>
                        </MenuItem>
                        <MenuItem divider />
                        <MenuItem className="react-contextmenu-item-send" onClick={() => updateLightIntensity({ 'Id': selectedcars, 'Name': contextName, 'Value': ($('#Light-SelLots').val()), 'Type': 'Lot' })}>
                            {props.t('allgemein.senden')}
                        </MenuItem>
                    </SubMenu>
                )
            }
        }


        return (
            <div id="contextmenu2" className="contextmenu">
                {addLightSel()}
                {/* <MenuItem divider />
   <SubMenu title={<div className="submenu-title"> {props.t('elementeAllgemein.zeitueberwachung')} <i class="fas fa-chevron-right submenu-icon"></i> </div>} className="submenu">
  <MenuItem onClick={() =>  updateTimeMonitoring({'Id':selectedcars,'Name':contextName,'Mode':'Off'})}>
  {props.t('allgemein.aus')}
  </MenuItem>
  <MenuItem divider />
<MenuItem disabled>
 <div className="inputwrapper">
 <NumericInput id="TimeSelCon-dd" className="react-contextmenu-numeric react-contextmenu-item" value={0} mobile={"auto"} min={0} max={99} />
 <div class="inputtext contextmenu-item">dd</div>
 </div>
</MenuItem>
<MenuItem divider />
<MenuItem disabled>
<div className="inputwrapper">
<NumericInput id="TimeSelCon-hh" className="react-contextmenu-numeric react-contextmenu-item" value={0} mobile={"auto"} min={0} max={23} />
 <div class="inputtext contextmenu-item">hh</div>
</div>
</MenuItem>
<MenuItem divider />
<MenuItem disabled >
<div className="inputwrapper">
<NumericInput id="TimeSelCon-mm" className="react-contextmenu-numeric react-contextmenu-item" value={0} mobile={"auto"} min={0} max={59} />
 <div class="inputtext contextmenu-item">mm</div>
</div>
</MenuItem>
<MenuItem divider />
<MenuItem className="react-contextmenu-item-send" onClick={() =>  updateTimeMonitoring({'Id':selectedcars,'Name':contextName,'Value':[$('#TimeSelCon-dd').val(),$('#TimeSelCon-hh').val(),$('#TimeSelCon-mm').val()],'Mode':'On'})}> 
{props.t('allgemein.senden')}
  </MenuItem>
  </SubMenu>  */}

                <MenuItem divider />
                <SubMenu title={<div className="submenu-title">{props.t('allgemein.typ')}<i class="fas fa-chevron-right submenu-icon"></i> </div>} className="submenu">
                    {types != null &&
                        types.map(item =>
                            <>
                                <MenuItem onClick={() => updateTypes({ 'Id': selectedcars, 'Value': item.id, 'Type': 'Lot' })}>
                                    {item.name}
                                </MenuItem>
                                <MenuItem divider />
                            </>
                        )
                    }
                </SubMenu>
                <MenuItem divider />
                <SubMenu title={<div className="submenu-title"> {props.t('allgemein.modus')} <i class="fas fa-chevron-right submenu-icon"></i> </div>} className="submenu">
                    <MenuItem onClick={() => updateMode({ 'Id': selectedcars, 'Mode': '0', 'Type': 'Lot' })}>
                        {props.t('lot.modes.Automatic')}
                    </MenuItem>
                    <MenuItem divider />
                    <MenuItem onClick={() => updateMode({ 'Id': selectedcars, 'Mode': '1', 'Type': 'Lot' })}>
                        {props.t('lot.modes.ManualFree')}
                    </MenuItem>
                    <MenuItem divider />
                    <MenuItem onClick={() => updateMode({ 'Id': selectedcars, 'Mode': '2', 'Type': 'Lot' })}>
                        {props.t('lot.modes.ManualOccupied')}
                    </MenuItem>
                </SubMenu>
                <MenuItem divider />
                <SubMenu title={<div className="submenu-title"> {props.t('allgemein.reservierung')} <i class="fas fa-chevron-right submenu-icon"></i> </div>} className="submenu">
                    <MenuItem onClick={() => updateReserved({ 'Id': selectedcars, 'Reserved': "false", 'Type': 'Lot' })}>
                        {props.t('allgemein.aus')}
                    </MenuItem>
                    <MenuItem divider />
                    <MenuItem onClick={() => updateReserved({ 'Id': selectedcars, 'Reserved': "true", 'Type': 'Lot' })}>
                        {props.t('allgemein.an')}
                    </MenuItem>
                </SubMenu>
                {/* <MenuItem divider />
 <MenuItem onClick={showCalendar} className="activeitem">
 {props.t('kalender.kalenderEintrag')} 
  </MenuItem> */}
                <MenuItem divider />
                <MenuItem disabled >
                    {props.t('allgemein.selektiert')}:{selectedcars.length}
                </MenuItem>
            </div>
        )
    }

    // Render SVG Map
    function svgShow() {
        if (pageLoaded) {
            return (
                <div id="svgmap" style={{ backgroundImage: `url(${svgshow})` }}>
                    <TransformWrapper options={{ centerContent: false, animationTime: "0", maxScale: 30, defaultScale: 1 }} pan={{ velocity: false, animationTime: "0" }}
                        wheel={{ step: wheelstep }} reset={{ animation: false, animationTime: "0" }}>
                        {({ resetTransform }) => (
                            <TransformComponent>
                                {changeMap == true && (
                                    <>
                                        {resetZoom == true && (
                                            resetTransform()
                                        )}
                                        {currentmap}
                                    </>
                                )}
                            </TransformComponent>
                        )}
                    </TransformWrapper>
                    <div id="mapfs">
                        <i className="bx bx-fullscreen fsicon" onClick={() => { toggleFullscreen(); }}></i>
                    </div>
                    {popUpShow == true && (
                        <div>{popUp()}</div>)}
                </div>
            )
        }
    }

    // Render first row in SVG Map
    function firstRow() {
        function showAreasSide() {
            areaTabletOpen = true
            $(".table1").animate({ "right": "0px" }, "fast").show();
            $(".bodyoverlay").show()
        };

        function showTreeSide() {
            treeTabletOpen = true
            $("#parktreeall").animate({ "left": "0px" }, "fast").show();
            $(".bodyoverlay").show()

        };

        if (pageLoaded) {
            return (
                <div id="firstrow">
                    <div id="parkcurrent">{parkName}{activePark}{activePark2}</div>
                    <div id="selectmodus">
                        Select
                        <label class="switch switch-green">
                            <input type="checkbox" class="switch-input" onClick={() => tabletselectmode = !tabletselectmode} />
                            <span class="switch-label" data-on={props.t('allgemein.an')} data-off={props.t('allgemein.aus')}></span>
                            <span class="switch-handle"></span>
                        </label>
                    </div>
                    <div id="legend" onClick={() => setLegendShow(current => !current)}>{props.t('occupancy.legende.legende')}</div>
                    <div id="areasTablet" onClick={() => { showAreasSide() }}>Areas</div>
                    <div id="treeTablet" onClick={() => { showTreeSide() }}>Tree</div>
                </div>
            )
        }
    }


    return (
        <div>
            <div className="bodyoverlay"></div>
            {firstRow()}
            <div className="pagewrapper">
                {tree()}
                {svgShow()}
                {areasTable()}

                {loaderShow == true && (
                    <div className="loader">
                        <ReactLoading type={"bars"} color={"#2a3042"} height={80} width={80} name={"Loading"} />
                    </div>
                )}
            </div>

            <InfoAreas data={detailedAreaData} hoverstyle={hoverAreaContainer} id={hoverAreaId}></InfoAreas>
            {treeContextMenu()}
            {showLots()}
            {showTimeControl()}

            {contextSVG()}

            {contextSVG2()}

            {legendShow == true && (
                <div ref={legendContainer}>
                    <Legend></Legend>
                </div>
            )}


            {settingsShow == true && (
                <Draggable>
                    <div ref={settingsContainer} className="drag-container">
                        <Settings data={settingsData} type={settingsType} ></Settings>
                    </div>
                </Draggable>
            )}
        </div>
    )


}
export default withNamespaces()(Belegung);
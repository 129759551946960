import React, { useEffect } from 'react';
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { withNamespaces } from 'react-i18next';


function SidebarContent(props) {

    useEffect(() => {

        var pathName = props.location.pathname;

        const initMenu = () => {
            new MetisMenu("#side-menu");
            var matchingMenuItem = null;
            var ul = document.getElementById("side-menu");
            var items = ul.getElementsByTagName("a");
            for (var i = 0; i < items.length; ++i) {
                if (pathName === items[i].pathname) {
                    matchingMenuItem = items[i];
                    break;
                }
            }
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        }
        initMenu();
    }, [props.location.pathname]);


    // Highlights current page on the menu

    function activateParentDropdown(item) {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };

    //List of all pages - menu

    return (
        <div id="sidebar-menu" >
            <ul id="side-menu">

                <li className="menu-title">{props.t('allgemein.menu')}</li>

                <li>
                    <Link to="dashboard" >
                        <i className="bx bx-desktop"></i>
                        <span >{props.t('dashboard.dashboard')}</span>
                    </Link>

                </li>

                <li>
                    <Link to="occupancy">
                        <i className="bx bx-home"></i>
                        <span>{props.t('occupancy.occupancy')}</span>
                    </Link>
                </li>

               {/* <li>
                        <Link to="carfinder">
                            <i className="bx bx-search"></i>
                            <span>{props.t('carfinder.carfinder')}</span>
                        </Link>
                    </li>    */}

                <li>
                    <Link to="statistics">
                        <i className="bx bx-line-chart" ></i>
                        <span>{props.t('statistics.statistics')}</span>
                    </Link>
                </li>

                <li>
                    <Link to="events">
                        <i className="bx bx-spreadsheet"></i>
                        <span>{props.t('events.events')}</span>
                    </Link>

                </li>
                {/*       
                    <li>
                        <Link to="calendar">
                            <i className="bx bx-calendar"></i>
                            <span >{props.t('calender.calender')}</span>
                        </Link>
                      
                    </li> */}

                <li>
                    <Link to="settings">
                        <i className="bx bx-customize"></i>
                        <span>{props.t('allgemein.einstellungen')}</span>
                    </Link>
                </li>
            </ul>
        </div>

    );
}

export default withRouter(withNamespaces()(SidebarContent));


